import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { React } from "react";
import { FacebookShareButton } from "react-share";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

import FooterBackground from "../../image/Footer_bg_2.png";
import FbBtn from "../../image/FB_BTN.png";

const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative",
  },
  background: {
    width: "100%",
    maxWidth: "480px",
    verticalAlign: "middle",
  },
  grid: {
    position: "absolute",
    maxWidth: "100%",
    bottom: "15%",
  },
  gridSm: {
    bottom: "2.4vw",
  },
  footerText: {
    fontFamily: "MStiffHei HK",
    color: "#fff !important",
    fontWeight: "bold",
    maxWidth: "100%",
    fontSize: 14,
    margin: 0,
  },
  footerTextSm: {
    fontSize: "2.8vw",
  },
  button: {
    paddingLeft: "7%",
    textAlign: "left",
  },
  share: {
    textAlign: "right",
    paddingRight: "7%",
  },
  soicalIcon: {
    height: 25,
    verticalAlign: "middle",
  },
  soicalIconSm: {
    height: "5vw",
  },
  link: {
    verticalAlign: "middle",
  },
}));

const Footer2 = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  const { onClose } = props;

  return (
    <Box className={classes.box}>
      <Grid
        container
        justify="space-between"
        className={clsx(classes.grid, {
          [classes.gridSm]: matches,
        })}
      >
        <Grid item xs={7} className={classes.button}>
          <h5
            className={clsx(classes.footerText, {
              [classes.footerTextSm]: matches,
            })}
          >
            <Link
              to="/terms-and-conditions"
              onClick={onClose}
              className={classes.link}
            >
              <span
                type="button"
                className={clsx(classes.footerText, {
                  [classes.footerTextSm]: matches,
                })}
              >
                條款及細則
              </span>
            </Link>
            <span
              className={clsx(classes.link, {
                [classes.footerTextSm]: matches,
              })}
            >
              {" | "}
            </span>
            <Link
              to="/privacy-policy"
              onClick={onClose}
              className={classes.link}
            >
              <span
                type="button"
                className={clsx(classes.footerText, {
                  [classes.footerTextSm]: matches,
                })}
              >
                私隱聲明
              </span>
            </Link>
          </h5>
        </Grid>
        <Grid item xs={5} className={classes.share}>
          <h5
            className={clsx(classes.footerText, classes.link, {
              [classes.footerTextSm]: matches,
            })}
          >
            分享至：{" "}
            <FacebookShareButton
              url={"https://www.calbee-promo.com/"}
              quote={
                "我參加咗卡樂B《食港產片睇港產片》獎賞計劃呀，食住香港製造嘅卡樂B港產薯片，支持港產片，你都一齊參加啦！"
              }
              hashtag={"#calbeehk"}
            >
              <img
                src={FbBtn}
                className={clsx(classes.soicalIcon, {
                  [classes.soicalIconSm]: matches,
                })}
              ></img>
            </FacebookShareButton>
          </h5>
        </Grid>
      </Grid>
      <img src={FooterBackground} className={classes.background} />
    </Box>
  );
};

export default Footer2;
