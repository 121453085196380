import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, CircularProgress, Dialog } from "@material-ui/core";
import { SuccessDialog } from "./SuccessDialog";
import { apiService } from "../../../services";
import { API_URL } from "../../../constants";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

import BtnCancel from "../../../image/Btn_cancel.png";
import BtnConfirm from "../../../image/Btn_Confirm_Green.png";
import BtnClose from "../../../image/Btn_Close.png";
import Add from "../../../image/Btn_Add@2x.png";
import Minus from "../../../image/Btn_minus@2x.png";
import CounterBorder from "../../../image/counter_display@2x.png";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: "480px",
    margin: "auto",
  },
  box: {
    textAlign: "center",
    maxWidth: "100%",
    position: "relative",
  },
  closeGrid: {
    position: "absolute",
    padding: "5%",
    maxWidth: "100%",
    zIndex: 10,
    textAlignLast: "right",
  },
  close: {
    width: "10%",
  },
  button: {
    maxWidth: "100%",
    width: "100%",
    justifyContent: "right !important",
    textAlign: "right",
  },
  grid: {
    maxWidth: "100%",
    padding: "10% 5% 5% 5%",
  },
  moviePoster: {
    width: "40%",
  },
  btnImage: {
    maxWidth: "100%",
  },
  loadingGrid: {
    padding: "20%",
    width: "320px !important",
    textAlign: "center",
  },
  error: {
    color: "#ec1c24",
  },
  des: {
    fontWeight: "normal",
  },
  iconBtn: {
    width: "23%",
    verticalAlign: "middle",
  },
  iconMinus: {
    textAlign: "right",
    maxWidth: "100%",
    width: "100%",
  },
  iconAdd: {
    textAlign: "left",
    maxWidth: "100%",
    width: "100%",
  },
  counterBorder: {
    maxWidth: "100%",
    width: "70%",
  },
  counterGrid: {
    position: "relative",
  },
  number: {
    position: "absolute",
    left: "36%",
    paddingTop: "1%",
  },
  numberSm: {
    fontSize: "6vw",
  },
  numberLg: {
    fontSize: "28px",
  },
}));

export function ConfirmDialog(props) {
  const classes = useStyles();
  const { open, onClose, entity } = props;
  const matches = useMediaQuery("(max-width:480px)");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [count, setCount] = useState(1);

  const handleMinus = () => {
    if (count !== 1 && count > 1) {
      setCount(count - 1);
    }
  };

  const handleAdd = () => {
    if (count !== 9 && count < 9) {
      setCount(count + 1);
    }
  };

  const onRedem = (prizeId) => {
    apiService
      .post(API_URL.PRIZE_REDEEM + "/" + prizeId, { qty: count })
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        onClose();
      })
      .catch(function (error) {
        if (error.response.data.error.code == "NOT_ENOUGH_POINTS") {
          setError("電影時數不足！");
          setLoading(false);
        } else if (error.response.data.error.code == "NOT_FOUND") {
          setError("可供換領的電影贈券數量不足！");
          setLoading(false);
        } else {
          setError("錯誤");
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setCount(1);
  }, [entity]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      className={classes.dialog}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      {!loading && (
        <Box className={classes.box}>
          <Grid container className={classes.closeGrid}>
            <Grid item xs={12}>
              <button
                onClick={() => {
                  onClose();
                  setError("");
                }}
                className={classes.button}
              >
                <img className={classes.close} src={BtnClose} />
              </button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box className={classes.box}>
        {loading ? (
          <Grid container className={classes.loadingGrid}>
            <Grid item xs={12}>
              <h4 className={classes.des}>換領中...請稍後</h4>
              <CircularProgress className={classes.loading} />
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <h4 className={classes.des}>
                你確認換領<br></br>
                {entity.remarks !== null
                  ? entity.name +
                    entity.email_description +
                    entity.remarks +
                    count +
                    entity.unit
                  : entity.name +
                    entity.email_description +
                    count +
                    entity.unit}
                ？
              </h4>
            </Grid>
            <Grid item xs={12}>
              <img src={entity.image} className={classes.moviePoster} />
            </Grid>
            <Grid item xs={12}>
              <h4 className={classes.des}>請選擇數量</h4>
              <Grid container>
                <Grid item xs={5} alignItems="right">
                  <button
                    disabled={count < 1 || count === 1}
                    className={classes.iconMinus}
                    onClick={() => {
                      handleMinus();
                    }}
                  >
                    <img className={classes.iconBtn} src={Minus} />
                  </button>
                </Grid>
                <Grid item xs={2} className={classes.counterGrid}>
                  <div
                    className={clsx(classes.number, {
                      [classes.numberSm]: matches,
                      [classes.numberLg]: !matches,
                    })}
                  >
                    {count}
                  </div>
                  <img className={classes.counterBorder} src={CounterBorder} />
                </Grid>
                <Grid item xs={5}>
                  <button
                    disabled={count > 9 || count === 9}
                    className={classes.iconAdd}
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    <img className={classes.iconBtn} src={Add} />
                  </button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h4 className={classes.error}>{error}</h4>
            </Grid>
            <Grid item xs={6}>
              <button
                onClick={() => {
                  onClose();
                  setError("");
                }}
              >
                <img className={classes.btnImage} src={BtnCancel} />
              </button>
            </Grid>
            <Grid item xs={6}>
              <button
                onClick={() => {
                  onRedem(entity.id);
                  setLoading(true);
                  setError("");
                }}
              >
                <img className={classes.btnImage} src={BtnConfirm} />
              </button>
            </Grid>
          </Grid>
        )}
      </Box>
      <SuccessDialog
        onClose={() => setSuccess(false)}
        open={success}
        entity={entity}
        count={count}
      />
    </Dialog>
  );
}
