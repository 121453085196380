import { React, Fragment, useState } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthService from "../../services/auth.service";
import { history } from "../../history";
import { connect } from "react-redux";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_Login@2x.png";
import FillForm from "../../image/Fill_form.png";
import BtnReg from "../../image/Btn_Reg.png";
import BtnLogin from "../../image/Btn_Login.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 40,
    marginBottom: -45,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingBottom: "5.5%",
    borderRadius: "15px !important",
  },
  textfield: {
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    maxWidth: "100%",
    padding: "0 15px 0 15px",
  },
  inputBackground: {
    maxWidth: "100%",
    backgroundImage: `url(${FillForm}) !important`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "10%",
    padding: "0 7% 0 7%",
  },
  input: {
    maxWidth: "100%",
    width: "80%",
    padding: "0 7% 0 7%",
  },
  inputLg: {
    height: "75px",
  },
  inputSm: {
    height: "15.5vw",
  },
  buttonImage: {
    maxWidth: "100%",
  },
  forgotPw: {
    color: "black !important",
    fontFamily: "MStiffHei HK",
    marginBottom: "16%",
    fontSize: 14,
  },
  fieldArea: {
    padding: "20% 5% 3% 5%",
  },
  errorMsg: {
    color: "#ec1c24",
    fontSize: 14,
  },
  error: {
    color: "#ec1c24",
  },
}));

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("請輸入正確的電郵").required("電郵為必填項目"),
  password: Yup.string()
    .min(8, "密碼最少為8個字元")
    .matches(
      /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x]).*$/,
      "密碼必須包含英文及數字"
    )
    .required("密碼為必填項目"),
});

function LoginPage() {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const location = useLocation();
  const matches = useMediaQuery("(max-width:480px)");
  const [error, setError] = useState("");

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box>
          <Paper elevation={4} className={classes.content}>
            <Formik
              enableReinitialize
              initialValues={{
                email: "",
                password: "",
                rememberMe: false,
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, actions) => {
                const { from } = location.state || {
                  from: { pathname: "/account" },
                };
                AuthService.login(
                  values.email.toLowerCase(),
                  values.password,
                  values.rememberMe
                ).then(
                  (user) => {
                    history.push(from);
                  },
                  (error) => {
                    if (
                      error?.response?.data?.error?.code ==
                      "INVALID_CREDENTIALS"
                    ) {
                      setError("電郵或密碼錯誤！");
                    } else if (
                      error?.response?.data?.error?.code == "NOT_VERIFIED"
                    ) {
                      setError("請開啟確認電郵以驗證帳戶！");
                    } else {
                      setError("錯誤");
                    }
                    actions.setSubmitting(false);
                  }
                );

                // dispatch(
                //   authActions.login(values.email, values.password, from)
                // );
              }}
            >
              {({ errors, touched, handleSubmit, isSubmitting, values }) => (
                <Form autoComplete="off">
                  <Grid container className={classes.fieldArea}>
                    <Grid item xs={12}>
                      <Box className={classes.textfield}>
                        <div className={classes.inputBackground}>
                          <Field
                            type="text"
                            name="email"
                            placeholder="電郵"
                            className={clsx(classes.input, {
                              [classes.inputSm]: matches,
                              [classes.inputLg]: !matches,
                            })}
                          />
                        </div>
                        {errors.email && touched.email ? (
                          <div className={classes.errorMsg}>{errors.email}</div>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.textfield}>
                        <div className={classes.inputBackground}>
                          <Field
                            type="password"
                            name="password"
                            placeholder="密碼"
                            className={clsx(classes.input, {
                              [classes.inputSm]: matches,
                              [classes.inputLg]: !matches,
                            })}
                          />
                        </div>
                        {errors.password && touched.password ? (
                          <div className={classes.errorMsg}>
                            {errors.password}
                          </div>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Box className={classes.textfield}>
                        <Field
                          type="checkbox"
                          id="rememberMe"
                          name="rememberMe"
                        />
                        <label for="rememberMe" className={classes.rememberMe}>
                          記住我
                        </label>
                        <br></br>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <h4 className={classes.error}>{error}</h4>
                    </Grid>
                    <Grid container>
                      <Grid item item xs={6} style={{ marginTop: 20 }}>
                        <Link to="/registration">
                          <button type="button" onClick={() => setError("")}>
                            <img className={classes.buttonImage} src={BtnReg} />
                          </button>
                        </Link>
                      </Grid>
                      <Grid item item xs={6} style={{ marginTop: 20 }}>
                        <button
                          type="submit"
                          tabindex="-1"
                          disabled={isSubmitting}
                          onClick={() => setError("")}
                        >
                          <img className={classes.buttonImage} src={BtnLogin} />
                        </button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Box className={classes.textfield}>
                        <Link to="/forgot-password">
                          <button
                            type="button"
                            className={classes.forgotPw}
                            onClick={() => setError("")}
                          >
                            忘記密碼？
                          </button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
}

export default connect(null, {})(LoginPage);
