import { Grid, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { React, useState } from "react";
import Menu from "../Menu";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenuButton from "../../image/Btn_Menu.png";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: "3.5%",
    paddingLeft: "2%",
    paddingRight: "2%",
    position: "absolute !important",
    zIndex: 10,
    maxWidth: "100%",
    width: "90%",
  },
  imageLg: {
    height: 44,
  },
  imageSm: {
    height: "10vw",
  },
  grid: {
    maxWidth: "100%",
    marginLeft: "1%",
  },
  drawer: {
    maxWidth: "100%",
    width: "100vw",
    textAlign: "center",
  },
  root: {
    maxWidth: "100%",
  },
}));

const Header = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(max-width:480px)");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <div className={classes.header}>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <button onClick={toggleDrawer(true)}>
            <img
              src={MenuButton}
              className={clsx({
                [classes.imageSm]: matches,
                [classes.imageLg]: !matches,
              })}
            />
          </button>
          <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            className={classes.drawer}
          >
            <Menu
              open={open}
              onClose={toggleDrawer(false)}
              className={classes.root}
            />
          </Drawer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
