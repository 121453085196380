import axios from "axios";
import { AuthService } from "../services";

class axiosConfig {
  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return new Promise(() => {
          let response = error.response;

          if (AuthService.isErrorResponse(response)) {
            AuthService.handleErrorResponse(response);
          }

          throw error;
        });
      }
    );
  };

  setAuthorizationHeader = (accessToken) => {
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  removeAuthorizationHeader = () => {
    this.setAuthorizationHeader(null);
  };
}

const instance = new axiosConfig();

export default instance;
