import { React, Fragment, useState } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { API_URL } from "../../constants";
import { Formik, Form, Field } from "formik";
import { apiService } from "../../services";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_ForgetPW@2x.png";
import FillForm from "../../image/Fill_form.png";
import BtnCancel from "../../image/Btn_cancel.png";
import BtnConfirmGreen from "../../image/Btn_Confirm_Green.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingBottom: "5.5%",
    borderRadius: "15px !important",
  },
  textfield: {
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    maxWidth: "100%",
    padding: "0 15px 0 15px",
  },
  inputBackground: {
    maxWidth: "100%",
    backgroundImage: `url(${FillForm}) !important`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "10%",
    padding: "0 7% 0 7%",
  },
  input: {
    maxWidth: "100%",
    width: "80%",
    padding: "0 7% 0 7%",
  },
  inputLg: {
    height: "75px",
  },
  inputSm: {
    height: "15.5vw",
  },
  des: {
    fontWeight: "normal",
  },
  buttonImage: {
    maxWidth: "100%",
    width: "950%",
  },
  fieldArea: {
    padding: "30% 5% 30% 5%",
  },
  errorMsg: {
    color: "#ec1c24",
    fontSize: 14,
  },
  error: {
    color: "#ec1c24",
  },
}));

const EmailSchema = Yup.object().shape({
  email: Yup.string().email("請輸入正確的電郵").required("必填項目"),
  password: Yup.string()
    .min(8, "密碼最少為8個字元")
    .matches(
      /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x]).*$/,
      "密碼必須包含英文及數字"
    )
    .required("密碼為必填項目"),
});

const ResetPasswordPage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  const [error, setError] = useState("");
  let { id } = useParams();

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box>
          <Paper elevation={4} className={classes.content}>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={EmailSchema}
              onSubmit={(values, actions) => {
                apiService
                  .post(API_URL.RESET_PASSWORD, {
                    email: values.email.toLowerCase(),
                    password: values.password,
                    password_confirmation: values.password,
                    token: id,
                  })
                  .then((response) => {
                    window.location = "/reset-password-confirm";
                    setError("");
                  })
                  .catch(function (error) {
                    if (
                      error?.response?.data?.message === "Too many requests"
                    ) {
                      setError("請求過於頻密，請稍後再試！");
                    } else if (
                      error?.response?.data?.message ===
                      "Emails address not found"
                    ) {
                      setError("電郵未有被註冊！");
                    } else if (
                      error?.response?.data?.message === "Invalid token"
                    ) {
                      setError("Token 已過期，請重試！");
                    } else if (
                      error?.response?.data?.error?.fieldErrors?.email &&
                      error?.response?.data?.error?.fieldErrors?.email[0] ===
                        "The selected email is invalid."
                    ) {
                      setError("電郵未有申請重設密碼！");
                    } else {
                      setError("錯誤");
                    }
                    actions.setSubmitting(false);
                  });
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <Grid container className={classes.fieldArea}>
                    <Grid item xs={12}>
                      <h4 className={classes.des}>請提供以下資料核實身份</h4>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.textfield}>
                        <div className={classes.inputBackground}>
                          <Field
                            type="text"
                            name="email"
                            placeholder="請輸入登記電郵"
                            className={clsx(classes.input, {
                              [classes.inputSm]: matches,
                              [classes.inputLg]: !matches,
                            })}
                            autocomplete="off"
                          />
                        </div>
                        {errors.email && touched.email ? (
                          <div className={classes.errorMsg}>{errors.email}</div>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.textfield}>
                        <div className={classes.inputBackground}>
                          <Field
                            type="password"
                            name="password"
                            placeholder="請輸入新密碼"
                            className={clsx(classes.input, {
                              [classes.inputSm]: matches,
                              [classes.inputLg]: !matches,
                            })}
                            autocomplete="off"
                          />
                        </div>
                        {errors.password && touched.password ? (
                          <div className={classes.errorMsg}>
                            {errors.password}
                          </div>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <h4 className={classes.error}>{error}</h4>
                    </Grid>
                    <Grid container style={{ marginTop: "5%" }}>
                      <Grid item item xs={6}>
                        <Link to="/login">
                          <button onClick={() => setError("")}>
                            <img
                              className={classes.buttonImage}
                              src={BtnCancel}
                            />
                          </button>
                        </Link>
                      </Grid>
                      <Grid item item xs={6}>
                        <button
                          type="submit"
                          onClick={() => setError("")}
                          disabled={isSubmitting}
                        >
                          <img
                            className={classes.buttonImage}
                            src={BtnConfirmGreen}
                          />
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ResetPasswordPage;
