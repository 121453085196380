import { React, Fragment } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

import HomeBanner from "../../image/Home_KV.png";
import BtnJoinNow from "../../image/BTN_CTA1.png";
import Slider from "../../image/Home_Leadin.jpg";
import HomeIntro from "../../image/Home_intro.png";
import HomeOutro from "../../image/Home_Outro.png";
import HomeOutroWoman1 from "../../image/Home_outro_Woman1.png";
import BtnGameRule from "../../image/Btn_GameRule.png";
import BtnRegLogin from "../../image/Btn_Reg_Login.png";

const useStyles = makeStyles((theme) => ({
  rightBox: {
    textAlign: "right",
    position: "relative",
  },
  centertBox: {
    textAlign: "center",
    position: "relative",
  },
  background: {
    width: "100%",
    marginBottom: -5,
  },
  joinNowGrid: {
    position: "absolute",
    maxWidth: "100%",
    marginTop: "76%",
  },
  joinNow: {
    width: "25%",
    paddingRight: "3.5%",
  },
  peopleGrid: {
    textAlign: "right",
    position: "absolute",
    maxWidth: "100%",
  },
  people: {
    width: "19%",
    paddingRight: "13%",
  },
  buttonGrid: {
    position: "absolute",
    maxWidth: "100%",
    marginTop: "47%",
  },
  btnImage: {
    width: "38%",
    padding: "0 2% 0 2%",
  },
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box>
        <Box className={classes.rightBox}>
          <Grid container className={classes.joinNowGrid}>
            <Grid item xs={12}>
              <Link to="/account">
                <img
                  src={BtnJoinNow}
                  className={classes.joinNow}
                  id="calbee-join-now-btn"
                />
              </Link>
            </Grid>
          </Grid>
          <img src={HomeBanner} className={classes.background} />
        </Box>
        <Box>
          <img src={Slider} className={classes.background} />
        </Box>
        <Box>
          <img src={HomeIntro} className={classes.background} />
        </Box>
        <Box className={classes.centertBox}>
          <Grid container className={classes.peopleGrid}>
            <Grid item xs={12}>
              <img src={HomeOutroWoman1} className={classes.people} />
            </Grid>
          </Grid>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={12}>
              <Link to="/game-intro">
                <img className={classes.btnImage} src={BtnGameRule} />
              </Link>
              <Link to="/account">
                <img className={classes.btnImage} src={BtnRegLogin} />
              </Link>
            </Grid>
          </Grid>
          <img src={HomeOutro} className={classes.background} />
        </Box>
      </Box>
    </Fragment>
  );
};

export default HomePage;
