import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { Router, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import { React, useEffect } from "react";
import theme from "./styles/theme";
import "./styles/custom.css";
import { PrivateRoute, PublicRoute } from "../src/routes";
import { ACCESS_TOKEN_KEY } from "./constants/auth.constant";
import { axiosConfig } from "./config";
import { ROUTES } from "./constants";
import { history } from "./history";
import store from "./stores";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

import Footer from "./component/Footer";
import Footer2 from "./component/Footer2";
import Header from "./component/Header";
import AccountPage from "../src/pages/AccountPage";
import ForgotPasswordPage from "../src/pages/ForgotPasswordPage";
import GameIntroPage from "../src/pages/GameIntroPage";
import HomePage from "../src/pages/HomePage";
import LoginPage from "../src/pages/LoginPage";
import PrivacyPolicyPage from "../src/pages/PrivacyPolicyPage";
import QrCodePage from "../src/pages/QrCodePage";
import RankingPage from "../src/pages/RankingPage";
import RedemptionPage from "../src/pages/RedemptionPage";
import RegistrationPage from "../src/pages/RegistrationPage";
import TermsAndConditionsPage from "../src/pages/TermsAndConditionsPage";
import RegistrationConfirmPage from "./pages/RegistrationConfirmPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPasswordConfirmPage from "./pages/ForgotPasswordConfirmPage";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import ScrollToTop from "./hooks/scrollToTop";
import AccountVerificationPage from "./pages/AccountVerificationPage";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    fontFamily: "MStiffHei HK",
  },
  content: {
    backgroundColor: "white",
    maxWidth: 480,
    margin: "0 auto",
    position: "relative !important",
    width: "100%",
  },
  contentLg: {
    height: "100vh",
    overflowX: "hidden",
  },
}));

function App() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");

  axiosConfig.setAuthorizationHeader(localStorage.getItem(ACCESS_TOKEN_KEY));

  ReactGA.initialize("UA-213352864-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });

  if (window.location.href.indexOf("//calbee-promo.com") !== -1) {
    window.location.href = window.location.href.replace(
      "calbee-promo.com",
      "www.calbee-promo.com"
    );
  }

  useEffect(() => {}, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <style jsx global>{`
            body {
              margin: 0px;
              padding: 0px;
            }
          `}</style>
          <Helmet htmlAttributes={{ lang: "zh-Hant" }} />
          <div
            className={clsx(classes.content, {
              [classes.contentLg]: !matches,
            })}
          >
            <Router basename={""} history={history}>
              <ScrollToTop />
              <Header />
              <Switch>
                <PublicRoute exact path={ROUTES.HOME} component={HomePage} />
                <PublicRoute
                  exact
                  path={ROUTES.GAME_INTRO}
                  component={GameIntroPage}
                />
                <PublicRoute exact path={ROUTES.LOGIN} component={LoginPage} />
                <PublicRoute
                  exact
                  path={ROUTES.REGISTRATION}
                  component={RegistrationPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.REGISTRATION_CONFIRM}
                  component={RegistrationConfirmPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.ACCOUNT_VERIFICATION}
                  component={AccountVerificationPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.FORGOT_PASSWORD}
                  component={ForgotPasswordPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.FORGOT_PASSWORD_CONFIRM}
                  component={ForgotPasswordConfirmPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.RESET_PASSWORD}
                  component={ResetPasswordPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.RESET_PASSWORD_CONFIRM}
                  component={ResetPasswordConfirm}
                />
                <PublicRoute
                  exact
                  path={ROUTES.TERMS_AND_CONDITIONS}
                  component={TermsAndConditionsPage}
                />
                <PublicRoute
                  exact
                  path={ROUTES.PRIVACY_POLICY}
                  component={PrivacyPolicyPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.ACCOUNT}
                  component={AccountPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.REDEMPTION}
                  component={RedemptionPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.QR_CODE}
                  component={QrCodePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.QR_CODE_ID}
                  component={QrCodePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.RANKING}
                  component={RankingPage}
                />
                <Redirect from="*" to={ROUTES.HOME} />
              </Switch>
              {window.location.pathname == "/" ||
              window.location.pathname == "/game-intro" ? (
                <Footer />
              ) : (
                <Footer2 />
              )}
            </Router>
          </div>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
