import { React } from "react";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import { Box, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import BtnMyAcc from "../../../image/Btn_My_ACC.png";
import BtnContRedeem from "../../../image/Btn_Cont_Redeem.png";
import BtnClose from "../../../image/Btn_Close.png";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: "480px",
    margin: "auto",
  },
  box: {
    textAlign: "center",
    maxWidth: "100%",
    position: "relative",
  },
  closeGrid: {
    position: "absolute",
    padding: "5%",
    maxWidth: "100%",
    zIndex: 10,
    textAlignLast: "right",
  },
  close: {
    width: "10%",
  },
  button: {
    maxWidth: "100%",
    width: "100%",
    justifyContent: "right !important",
    textAlign: "right",
  },
  grid: {
    maxWidth: "100%",
    padding: "10% 5% 5% 5%",
  },
  heading: {
    color: "red",
    fontWeight: "normal",
    margin: 0,
  },
  moviePoster: {
    marginTop: "10%",
    width: "40%",
  },
  des: {
    padding: "0 10% 2% 10%",
    fontWeight: "normal",
  },
  btnImage: {
    maxWidth: "100%",
    width: "90%",
  },
}));

export function SuccessDialog(props) {
  const classes = useStyles();
  const { open, onClose, entity, count } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      className={classes.dialog}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <Box className={classes.box}>
        <Grid container className={classes.closeGrid}>
          <Grid item xs={12}>
            <button onClick={onClose} className={classes.button}>
              <img className={classes.close} src={BtnClose} />
            </button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <h3 className={classes.heading}>恭喜！</h3>
            <h4 className={classes.heading}>
              你已成功換領<br></br>
              {entity.name +
                " " +
                entity.email_description +
                count +
                entity.unit}
            </h4>
          </Grid>
          <Grid item xs={12}>
            <img src={entity.image} className={classes.moviePoster} />
          </Grid>
          <Grid item xs={12}>
            <h5 className={classes.des}>
              換領通知已發送至閣下的電郵，請根據指定換領步驟領取電影贈券
            </h5>
          </Grid>
          <Grid item xs={6}>
            <Link to="/account">
              <button type="button">
                <img className={classes.btnImage} src={BtnMyAcc} />
              </button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <button onClick={onClose}>
              <img className={classes.btnImage} src={BtnContRedeem} />
            </button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
