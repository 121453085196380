export const ROUTES = {
  // 不需登入頁面
  HOME: "/",
  GAME_INTRO: "/game-intro",
  LOGIN: "/login",
  REGISTRATION: "/registration",
  REGISTRATION_CONFIRM: "/registration-confirm",
  ACCOUNT_VERIFICATION: "/auth/email/verify/:id/:hash",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_CONFIRM: "/forgot-password-confirm",
  RESET_PASSWORD: "/auth/password/reset/:id",
  RESET_PASSWORD_CONFIRM: "/reset-password-confirm",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  COMING_SOON: "/coming-soon",
  GAME_END: "/game-end",

  // 需登入頁面
  ACCOUNT: "/account",
  REDEMPTION: "/redemption",
  QR_CODE: "/qr",
  QR_CODE_ID: "/qr/:id",
  RANKING: "/ranking",
};
