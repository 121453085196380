import { React, Fragment, useState, useEffect } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { apiService } from "../../services";
import { API_URL } from "../../constants";
import { ConfirmDialog } from "./component/ConfirmDialog";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_Redeem@2x.png";
import BtnRedeemRed from "../../image/Btn_Redeem_Small_red.png";
import BtnRedeemGrey from "../../image/Btn_Redeem_Small_Grey.png";
import BtnMyAcc from "../../image/Btn_My_ACC.png";
import BtnQr from "../../image/Btn_QR.png";
import MinsBlack from "../../image/Mins_Black.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "5%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    margin: "0 5.5% 4% 5.5%",
    padding: "2% 2% 2% 3%",
    borderRadius: "6px !important",
  },
  grid: {
    alignSelf: "center",
  },
  remarkGrid: {
    paddingTop: "5%",
  },
  buttonGrid: {
    paddingleft: "15%",
  },
  left: {
    textAlign: "left",
    paddingLeft: "3%",
  },
  moviePoster: {
    width: "100%",
  },
  des: {
    letterSpacing: 0,
    margin: 0,
    fontWeight: "normal",
  },
  desSm: {
    fontSize: "3vw",
  },
  number: {
    fontFamily: "DIN",
  },
  btnImageSmall: {
    marginTop: "2%",
    maxWidth: "100%",
    width: "70%",
    cursor: "pointer",
  },
  btnImageGrey: {
    marginTop: "2%",
    maxWidth: "100%",
    width: "70%",
  },
  btnImage: {
    maxWidth: "100%",
    width: "38%",
    padding: "0 2% 0 2%",
  },
  greyText: {
    color: "#95979a",
  },
  buttonGrid: {
    maxWidth: "100%",
    paddingTop: "2%",
  },
  minsGrid: {
    position: "relative",
    alignSelf: "flex-end",
  },
  minsBorder: {
    maxWidth: "100%",
    width: "75%",
  },
  number: {
    position: "absolute",
    fontFamily: "DIN",
    right: "40%",
    marginTop: "1.5%",
  },
  numberSm: {
    fontSize: "8vw",
  },
  numberLg: {
    fontSize: "35px",
  },
  fieldArea: {
    padding: "0 30% 5% 30%",
  },
  pointBox: {
    maxWidth: "100%",
    textAlign: "center",
  },
  heading: {
    margin: 0,
  },
  haedingSm: {
    fontSize: "3.8vw",
  },
  unit: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

const RedemptionPage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");

  const [open, setOpen] = useState(false);
  const [prize, setPrize] = useState("");
  const [entity, setEntity] = useState("");
  const [myPoint, setMyPoint] = useState("");

  useEffect(() => {
    apiService.get(API_URL.AVAILABLE_PRIZE).then((response) => {
      let data = response.data;
      setPrize(data);
    });
    apiService.get(API_URL.MY_POINT).then((response) => {
      let data = response.data;
      setMyPoint(data);
    });
  }, [open]);

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box className={classes.pointBox}>
          <Grid container className={classes.fieldArea}>
            <Grid item xs={12} className={classes.minsGrid}>
              <h4
                className={clsx(classes.heading, {
                  [classes.haedingSm]: matches,
                })}
              >
                電影時數結餘
              </h4>
              <div
                className={clsx(classes.number, {
                  [classes.numberSm]: matches,
                  [classes.numberLg]: !matches,
                })}
              >
                {myPoint.remaining}
              </div>
              <img className={classes.minsBorder} src={MinsBlack} />
            </Grid>
          </Grid>
        </Box>
        <Box>
          {prize &&
            prize.map((item) => (
              <Paper elevation={4} className={classes.content}>
                <Grid container>
                  <Grid item xs={2.8} className={classes.grid}>
                    <img src={item.image} className={classes.moviePoster} />
                  </Grid>
                  <Grid
                    item
                    xs={4.8}
                    className={clsx(classes.grid, classes.left)}
                  >
                    <h4
                      className={clsx(classes.des, {
                        [classes.haedingSm]: matches,
                      })}
                    >
                      {item.name}
                    </h4>
                    <h5
                      className={clsx(classes.des, {
                        [classes.desSm]: matches,
                      })}
                    >
                      {item.description}
                    </h5>
                    <h5
                      className={clsx(classes.des, {
                        [classes.desSm]: matches,
                      })}
                    >
                      {item.remarks}
                    </h5>
                    <br></br>
                    <h5
                      className={clsx(classes.des, {
                        [classes.desSm]: matches,
                      })}
                    >
                      (所需時數：{item.point}分鐘)
                    </h5>
                  </Grid>
                  <Grid item xs={4.4} className={classes.grid}>
                    {item.available_prize_items_count === 0 ? (
                      <div>
                        <h5 className={classes.des}>{item.remarks2}</h5>
                        <img
                          src={BtnRedeemGrey}
                          className={classes.btnImageGrey}
                        />
                        <h5
                          className={clsx(classes.des, classes.greyText, {
                            [classes.desSm]: matches,
                          })}
                        >
                          電影戲票已經換領完畢
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <h5
                          className={clsx(classes.des, {
                            [classes.desSm]: matches,
                          })}
                        >
                          {item.remarks2}
                        </h5>
                        <img
                          src={BtnRedeemRed}
                          className={classes.btnImageSmall}
                          onClick={() => {
                            setOpen(true);
                            setEntity(item);
                          }}
                        />
                        {/* <h5
                          className={clsx(classes.des, {
                            [classes.desSm]: matches,
                          })}
                        >
                          每週總數量：
                          {item.this_week_available_prize_items_count}張
                        </h5> */}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Box>
        <Box>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={12}>
              <Link to="/account">
                <img src={BtnMyAcc} className={classes.btnImage}></img>
              </Link>
              <Link to="/qr">
                <img src={BtnQr} className={classes.btnImage}></img>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12} className={classes.remarkGrid}>
              <h5
                className={clsx(classes.des, {
                  [classes.desSm]: matches,
                })}
              >
                *每週可供換領的數量：共600張
              </h5>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ConfirmDialog
        onClose={() => setOpen(false)}
        open={open}
        entity={entity}
      />
    </Fragment>
  );
};

export default RedemptionPage;
