import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../constants";
import AuthService from "../services/auth.service";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!AuthService.isloggedIn()) {
          return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        } else {
          // apiService
          //   .get("/api/auth/profile")
          //   .then((response) => {
          //     AuthService.storeUser(response.data);
          //     setLoaded(true);
          //   })
          //   .catch((error) => {});
          return <Component {...props} />;
        }
      }}
    />
  );
};
