import axios from "axios";
import { axiosConfig } from "../config";
import { API_URL } from "../constants/api.constant";
import { ACCESS_TOKEN_KEY, USER_KEY } from "../constants/auth.constant";

class AuthService {
  login(email, password, rememberMe) {
    return axios
      .post(API_URL.LOGIN, {
        email: email,
        password: password,
        rememberMe: rememberMe,
      })
      .then((response) => {
        if (this.isTokenResponse(response)) {
          localStorage.setItem(
            ACCESS_TOKEN_KEY,
            response.data[ACCESS_TOKEN_KEY]
          );
          axiosConfig.setAuthorizationHeader(response.data[ACCESS_TOKEN_KEY]);

          delete response.data[ACCESS_TOKEN_KEY];
          this.storeUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    axiosConfig.removeAuthorizationHeader();
  }

  isTokenResponse(response) {
    let data = response.data;
    return data && typeof data === "object" && ACCESS_TOKEN_KEY in data;
  }

  isErrorResponse(response) {
    return (
      response &&
      response.data &&
      typeof response.data === "object" &&
      "messageCode" in response.data &&
      "message" in response.data
    );
  }

  isloggedIn() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  handleErrorResponse(response) {
    let data = response.data;

    if (data.statusCode === 401) {
      this.logout();
    }
  }

  storeUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  user() {
    let user = localStorage.getItem(USER_KEY);

    if (user) {
      user = JSON.parse(user);
    }

    return user;
  }

  hasAnyPermission(requiredPermissions) {
    const user = this.user();

    if (!user || !user.permissions) return false;
    if (!requiredPermissions) return false;

    return user.permissions.filter((p) => requiredPermissions.includes(p))
      .length;
  }

  register(email, password, name, contactNo) {
    return axios.post(API_URL.SIGNUP, {
      email,
      password,
      name,
      contactNo,
    });
  }
}

export default new AuthService();
