import { React } from "react";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import { Box, Grid } from "@material-ui/core";

import BtnBack from "../../../image/Btn_Back.png";
import BtnQr from "../../../image/Btn_QR.png";
import BtnClose from "../../../image/Btn_Close.png";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: "480px",
    margin: "auto",
  },
  box: {
    textAlign: "center",
    maxWidth: "100%",
    position: "relative",
  },
  closeGrid: {
    position: "absolute",
    padding: "5%",
    maxWidth: "100%",
    zIndex: 10,
    textAlignLast: "right",
  },
  close: {
    width: "10%",
  },
  button: {
    maxWidth: "100%",
    width: "100%",
    justifyContent: "right !important",
    textAlign: "right",
  },
  grid: {
    maxWidth: "100%",
    padding: "30% 2% 30% 2%",
  },
  heading: {
    color: "red",
    fontWeight: "normal",
    margin: 0,
    paddingBottom: "5%",
  },
  moviePoster: {
    marginTop: "10%",
    width: "40%",
  },
  des: {
    padding: "0 10% 2% 10%",
    fontWeight: "normal",
  },
  btnImage: {
    maxWidth: "100%",
    width: "90%",
  },
  eng: {
    fontFamily: "DIN",
  },
}));

export function FailDialog(props) {
  const classes = useStyles();
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      className={classes.dialog}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <Box className={classes.box}>
        <Grid container className={classes.closeGrid}>
          <Grid item xs={12}>
            <button onClick={onClose} className={classes.button}>
              <img className={classes.close} src={BtnClose} />
            </button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <h3 className={classes.heading}>
              抱歉！此<span className={classes.eng}>QR Code</span>不正確或已獲取
              <br></br>電影時數
            </h3>
            <h3 className={classes.heading}>
              請重新掃瞄<span className={classes.eng}>QR Code</span>或返回
            </h3>
          </Grid>
          <Grid item xs={6}>
            <button onClick={onClose}>
              <img className={classes.btnImage} src={BtnBack} />
            </button>
          </Grid>
          <Grid item xs={6}>
            <button onClick={onClose}>
              <img className={classes.btnImage} src={BtnQr} />
            </button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
