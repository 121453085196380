import { React, Fragment, useEffect, useState } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { apiService } from "../../services";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_NewReg@2x.png";
import Btn_Login from "../../image/Btn_Login.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingBottom: "5.5%",
    borderRadius: "15px !important",
  },
  des: {
    fontWeight: "normal",
  },
  buttonImage: {
    maxWidth: "100%",
    width: "50%",
  },
  fieldArea: {
    padding: "50% 5% 50% 5%",
  },
}));

const AccountVerificationPage = () => {
  const classes = useStyles();
  let { id } = useParams();
  let { hash } = useParams();
  const [message, setMessage] = useState("");

  useEffect(() => {
    apiService
      .get(
        API_URL.ACCOUNT_VERIFICATION +
          "/" +
          id +
          "/" +
          hash +
          window.location.search
      )
      .then((response) => {
        let data = response.data;
        if (data == "Verified") {
          setMessage("電郵已驗證成功，請登入");
        } else if (data == "Already Verified User") {
          setMessage("電郵經已驗證，請登入");
        } else {
          setMessage(
            "錯誤的電郵或連結已失效，請電郵至xxxx@xxxxx.com 或致電 xxxxxxx 與我們聯絡"
          );
        }
      });
  }, []);

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box>
          <Paper elevation={4} className={classes.content}>
            <Grid container className={classes.fieldArea}>
              <Grid item xs={12}>
                <h4 className={classes.des}>{message}</h4>
              </Grid>
              <Grid item item xs={12} style={{ marginTop: 25 }}>
                <Link to="/login">
                  <button type="button" onClick>
                    <img className={classes.buttonImage} src={Btn_Login} />
                  </button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AccountVerificationPage;
