import { React, Fragment, useState } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as Yup from "yup";
import { API_URL } from "../../constants";
import { Formik, Form, Field } from "formik";
import { apiService } from "../../services";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_NewReg@2x.png";
import FillForm from "../../image/Fill_form.png";
import BtnConfirm from "../../image/Btn_Confirm_Red.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingBottom: "5.5%",
    borderRadius: "15px !important",
  },
  textfield: {
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    maxWidth: "100%",
    padding: "0 15px 0 15px",
  },
  inputBackground: {
    maxWidth: "100%",
    backgroundImage: `url(${FillForm}) !important`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "10%",
    padding: "0 7% 0 7%",
  },
  input: {
    maxWidth: "100%",
    width: "80%",
    padding: "0 7% 0 7%",
  },
  inputLg: {
    height: "75px",
  },
  inputSm: {
    height: "15.5vw",
  },
  buttonImage: {
    maxWidth: "100%",
    width: "50%",
  },
  fieldArea: {
    padding: "10% 5% 3% 5%",
  },
  errorMsg: {
    color: "#ec1c24",
    fontSize: 14,
  },
  error: {
    color: "#ec1c24",
  },
}));

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email("請輸入正確的電郵").required("電郵為必填項目"),
  password: Yup.string()
    .min(8, "密碼最少為8個字元")
    .matches(
      /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x]).*$/,
      "密碼必須包含英文及數字"
    )
    .required("密碼為必填項目"),
  name: Yup.string()
    .min(2, "姓名最少為2個字元")
    .max(50, "姓名最多為50個字元")
    .required("姓名為必填項目"),
  contact_no: Yup.string()
    .min(8, "手機號碼必需為8個數字")
    .max(8, "手機號碼必需為8個數字")
    .matches(/^[1-9][0-9]{7}$/, "請輸入正確的手機號碼")
    .required("手機號碼為必填項目"),
  agree: Yup.bool()
    .required("請接受本網站的條款及細則，以及私隱聲明所載的條文。")
    .oneOf([true], "請接受本網站的條款及細則及私隱聲明所載的條文。"),
});

const RegistrationPage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  const [error, setError] = useState("");

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Paper elevation={4} className={classes.content}>
          <Formik
            initialValues={{
              email: "",
              password: "",
              name: "",
              contact_no: "",
              agree: false,
            }}
            validationSchema={RegistrationSchema}
            onSubmit={(values, actions) => {
              apiService
                .post(API_URL.REGISTER, {
                  email: values.email.toLowerCase(),
                  password: values.password,
                  name: values.name,
                  contact_no: values.contact_no,
                })
                .then((response) => {
                  window.location = "/registration-confirm";
                })
                .catch(function (error) {
                  if (
                    error?.response?.data?.error?.fieldErrors?.contact_no &&
                    error?.response?.data?.error?.fieldErrors?.contact_no[0] ===
                      "The contact no has already been taken."
                  ) {
                    setError("手機號碼已被註冊！");
                  } else if (
                    error?.response?.data?.error?.fieldErrors?.email &&
                    error?.response?.data?.error?.fieldErrors?.email[0] ===
                      "The email has already been taken."
                  ) {
                    setError("電郵已被註冊！");
                  } else {
                    setError("錯誤");
                  }
                  actions.setSubmitting(false);
                });
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <Grid container className={classes.fieldArea}>
                  <Grid item xs={12} style={{ width: "100%" }}>
                    <Box className={classes.textfield}>
                      <div className={classes.inputBackground}>
                        <Field
                          type="text"
                          name="name"
                          placeholder="姓名"
                          className={clsx(classes.input, {
                            [classes.inputSm]: matches,
                            [classes.inputLg]: !matches,
                          })}
                          autocomplete="off"
                        />
                      </div>
                      <label>(須與身份證上名字相符)</label>
                      {errors.name && touched.name ? (
                        <div className={classes.errorMsg}>{errors.name}</div>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.textfield}>
                      <div className={classes.inputBackground}>
                        <Field
                          type="text"
                          name="email"
                          placeholder="電郵"
                          className={clsx(classes.input, {
                            [classes.inputSm]: matches,
                            [classes.inputLg]: !matches,
                          })}
                          autocomplete="off"
                        />
                      </div>
                      <label>
                        (須為真實電郵地址，換領通知將會發送至此電郵)
                      </label>
                      {errors.email && touched.email ? (
                        <div className={classes.errorMsg}>{errors.email}</div>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.textfield}>
                      <div className={classes.inputBackground}>
                        <Field
                          type="text"
                          name="contact_no"
                          placeholder="手機號碼"
                          className={clsx(classes.input, {
                            [classes.inputSm]: matches,
                            [classes.inputLg]: !matches,
                          })}
                          autocomplete="off"
                        />
                      </div>
                      {errors.contact_no && touched.contact_no ? (
                        <div className={classes.errorMsg}>
                          {errors.contact_no}
                        </div>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "3vh" }}>
                    <Box className={classes.textfield}>
                      <div className={classes.inputBackground}>
                        <Field
                          type="password"
                          name="password"
                          placeholder="密碼"
                          className={clsx(classes.input, {
                            [classes.inputSm]: matches,
                            [classes.inputLg]: !matches,
                          })}
                          autocomplete="off"
                        />
                      </div>
                      <label>(須為8個字元或以上並包含英文及數字)</label>
                      {errors.password && touched.password ? (
                        <div className={classes.errorMsg}>
                          {errors.password}
                        </div>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "left", padding: "0 7%" }}
                  >
                    <Field type="checkbox" id="agree" name="agree" />
                    <label for="agree" className={classes.agree}>
                      我已閱讀並接受本網站的條款及細則，以及私隱聲明所載的條文。
                    </label>
                    {errors.agree && touched.agree ? (
                      <div className={classes.errorMsg}>{errors.agree}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className={classes.error}>{error}</h4>
                  </Grid>
                  <Grid item item xs={12} style={{ marginTop: 20 }}>
                    <button
                      type="submit"
                      onClick={() => setError("")}
                      disabled={isSubmitting}
                    >
                      <img className={classes.buttonImage} src={BtnConfirm} />
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default RegistrationPage;
