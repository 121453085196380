import { AUTHENTICATION_STATUS } from "../constants";
import AuthService from "../services/auth.service";
import { history } from "../history";
import { ACCESS_TOKEN_KEY } from "../constants/auth.constant";
import { axiosConfig } from "../config";

export const authActions = {
  login,
  logout,
};

function login(email, password, from) {
  return (dispatch) => {
    dispatch(request({ email }));

    AuthService.login(email, password).then(
      (user) => {
        dispatch(success(user));
        history.push(from);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: AUTHENTICATION_STATUS.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: AUTHENTICATION_STATUS.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: AUTHENTICATION_STATUS.LOGIN_FAIL, error };
  }
}

function logout() {
  AuthService.logout();
  return { type: AUTHENTICATION_STATUS.LOGOUT };
}
