import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { React } from "react";
import { FacebookShareButton } from "react-share";

import FooterBackground2x from "../../image/Footer_BG.png";
import FbBtn from "../../image/FB_BTN.png";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "relative",
    backgroundImage: `url(${FooterBackground2x})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
  },
  footerText: {
    fontFamily: "MStiffHei HK",
    color: "#fff !important",
    fontWeight: "bold",
    maxWidth: "100%",
    fontSize: 14,
  },
  button: {
    textAlign: "left",
    paddingLeft: "7%",
  },
  share: {
    textAlign: "right",
    paddingRight: "7%",
  },
  soicalIcon: {
    height: 25,
    verticalAlign: "middle",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" className={classes.background}>
      <Grid item xs={7} alignContent="center" className={classes.button}>
        <h5 className={classes.footerText}>
          <Link to="/terms-and-conditions">
            <span type="button" className={classes.footerText}>
              條款及細則
            </span>
          </Link>
          {" | "}
          <Link to="/privacy-policy">
            <span type="button" className={classes.footerText}>
              私隱聲明
            </span>
          </Link>
        </h5>
      </Grid>
      <Grid item xs={5} className={classes.share}>
        <h5 className={classes.footerText}>
          分享至：{" "}
          <FacebookShareButton
            url={"https://www.calbee-promo.com/"}
            quote={
              "我參加咗卡樂B《食港產片睇港產片》獎賞計劃呀，食住香港製造嘅卡樂B港產薯片，支持港產片，你都一齊參加啦！"
            }
            hashtag={"#calbeehk"}
          >
            <img src={FbBtn} className={classes.soicalIcon}></img>
          </FacebookShareButton>
        </h5>
      </Grid>
    </Grid>
  );
};

export default Footer;
