import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../constants";
import AuthService from "../services/auth.service";

export const PublicRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (AuthService.isloggedIn()) {
          if (
            window.location.pathname === ROUTES.REGISTRATION ||
            window.location.pathname === ROUTES.REGISTRATION_CONFIRM ||
            window.location.pathname === ROUTES.ACCOUNT_VERIFICATION ||
            window.location.pathname === ROUTES.FORGOT_PASSWORD ||
            window.location.pathname === ROUTES.FORGOT_PASSWORD_CONFIRM ||
            window.location.pathname === ROUTES.RESET_PASSWORD ||
            window.location.pathname === ROUTES.RESET_PASSWORD_CONFIRM
          ) {
            return <Redirect to={{ pathname: ROUTES.ACCOUNT }} />;
          } else {
            return <Component {...props} />;
          }
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
