import { React, Fragment } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import intro1Bg from "../../image/Intro-1.jpg";
import intro2Bg from "../../image/Intro-2.jpg";
import intro3Bg from "../../image/Intro-3.png";
import BtnRedeemSmall from "../../image/Btn_Redeem_Small.png";
import BtnQrSmall from "../../image/Btn_QR_small.png";
import BtnRankSmallRed from "../../image/Btn_Rank_Small_Red.png";
import BtnBack from "../../image/Btn_Back.png";
import BtnJoin from "../../image/Btn_Join.png";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "center",
    position: "relative",
  },
  introBG: {
    maxWidth: "100%",
    marginBottom: -5,
  },
  intro2_1: {
    position: "absolute",
    maxWidth: "100%",
    marginTop: "113%",
  },
  intro2_2: {
    position: "absolute",
    maxWidth: "100%",
    marginTop: "226%",
  },
  intro3: {
    position: "absolute",
    maxWidth: "100%",
    marginTop: "83%",
    padding: "0 5%",
  },
  intro3Btn: {
    position: "absolute",
    maxWidth: "100%",
    marginTop: "135%",
    padding: "0 5%",
  },
  btnImageSmall: {
    width: "35%",
    padding: "0 2% 0 2%",
  },
  intro2btnImage: {
    width: "38%",
    padding: "0 2% 0 2%",
  },
  intro2btnRank: {
    width: "30%",
  },
  intro3btnImage: {
    width: "100%",
    padding: "0 2% 0 2%",
  },
  text: {
    margin: "1% 0 1% 0",
    fontSize: 15,
  },
  textSm: {
    fontSize: "3vw",
  },
}));

const GameIntroPage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  let history = useHistory();

  return (
    <Fragment>
      <Box>
        <Box className={classes.box}>
          <img src={intro1Bg} className={classes.introBG}></img>
        </Box>
        <Box className={classes.box}>
          <Grid container className={classes.intro2_1}>
            <Grid item xs={12}>
              <Link to="/redemption">
                <img
                  src={BtnRedeemSmall}
                  className={classes.intro2btnImage}
                ></img>
              </Link>
              <Link to="/qr">
                <img src={BtnQrSmall} className={classes.intro2btnImage}></img>
              </Link>
            </Grid>
          </Grid>
          <Grid container className={classes.intro2_2}>
            <Grid item xs={12}>
              <Link to="/ranking">
                <img
                  src={BtnRankSmallRed}
                  className={classes.intro2btnRank}
                ></img>
              </Link>
            </Grid>
          </Grid>
          <img src={intro2Bg} className={classes.introBG}></img>
        </Box>
        <Box className={classes.box}>
          <Grid container className={classes.intro3}>
            <Grid item xs={12}>
              <p
                className={clsx(classes.text, {
                  [classes.textSm]: matches,
                })}
              >
                <strong>*備註︰</strong>
              </p>
              <p
                className={clsx(classes.text, {
                  [classes.textSm]: matches,
                })}
              >
                需要事先致電(或<a href="https://wa.me/85264235491">WhatsApp</a>
                {")"}
                <a href="tel:+85264235491">+852 6423 5491</a>預約時間領取
              </p>
              <p
                className={clsx(classes.text, {
                  [classes.textSm]: matches,
                })}
              >
                領取日期︰逢星期一至日（公眾假期除外）
              </p>
              <p
                className={clsx(classes.text, {
                  [classes.textSm]: matches,
                })}
              >
                領取時間︰上午10時至下午1時，下午3時至6時
              </p>
              <p
                className={clsx(classes.text, {
                  [classes.textSm]: matches,
                })}
              >
                領取地址︰荔枝角青山道495號誠信中心1006室
              </p>
              <p
                className={clsx(classes.text, {
                  [classes.textSm]: matches,
                })}
              >
                如有任何查詢，可以電郵至
                <a href="mailto:info@calbee-promo.com">info@calbee-promo.com</a>
              </p>
            </Grid>
          </Grid>
          <Grid container className={classes.intro3Btn}>
            <Grid item xs={6}>
              <button onClick={() => history.goBack()}>
                <img src={BtnBack} className={classes.intro3btnImage}></img>
              </button>
            </Grid>
            <Grid item xs={6}>
              <button>
                <Link to="/account">
                  <img src={BtnJoin} className={classes.intro3btnImage}></img>
                </Link>
              </button>
            </Grid>
          </Grid>
          <img src={intro3Bg} className={classes.introBG}></img>
        </Box>
      </Box>
    </Fragment>
  );
};

export default GameIntroPage;
