import { createTheme } from "@mui/material/styles";
import MStiffHei_HK_Bold from "./fonts/MStiffHei-HK-Bold.ttf";

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: "MStiffHei",
    h3: {
      fontSize: 122,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'MStiffHei';
          font-style: bold;
          font-weight: bold;
          src: "local ("MStiffHei"), url(${MStiffHei_HK_Bold}) format('woff')";
        }
      `,
    },
  },
});

export default theme;
