import { React, Fragment } from "react";
import { Box, Paper, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_ForgetPW@2x.png";
import Btn_Login from "../../image/Btn_Login.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingBottom: "5.5%",
    borderRadius: "15px !important",
  },
  des: {
    fontWeight: "normal",
  },
  buttonImage: {
    maxWidth: "100%",
    width: "50%",
  },
  fieldArea: {
    padding: "50% 5% 50% 5%",
  },
}));

const ForgotPasswordConfirmPage = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box>
          <Paper elevation={4} className={classes.content}>
            <Grid container className={classes.fieldArea}>
              <Grid item xs={12}>
                <h4 className={classes.des}>
                  重設電郵已發送到閣下輸入的電郵地址，<br></br>請確認電郵
                </h4>
              </Grid>
              <Grid item item xs={12} style={{ marginTop: 25 }}>
                <Link to="/login">
                  <button type="button" onClick>
                    <img className={classes.buttonImage} src={Btn_Login} />
                  </button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ForgotPasswordConfirmPage;
