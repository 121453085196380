import { React, Fragment } from "react";
import { Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_TnC@2x.png";
import BtnBack from "../../image/Btn_Back.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    paddingBottom: "5%",
    maxWidth: "100%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
    position: "absolute",
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  body: {
    paddingTop: "36%",
  },
  content: {
    paddingTop: "8%",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    padding: "4%",
    borderRadius: "15px !important",
    textAlign: "left",
    maxWidth: "100%",
    height: "55vh",
    overflow: "auto",
  },
  button: {
    paddingTop: "7%",
  },
  buttonImage: {
    maxWidth: "100%",
    width: "40%",
  },
}));

const TermsAndConditionsPage = () => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box className={classes.body}>
          <Paper elevation={4} className={classes.content}>
            <p>
              <strong>《食港產片睇港產片》條款及細則</strong>
              <br></br>
              歡迎參加《食港產片睇港產片》獎賞計劃
              帳戶申請受以下條款及細則規定。《食港產片睇港產片》獎賞計劃由卡樂B四洲有限公司（以下簡稱「卡樂B」）擁有及經營。請仔細閱讀此條款及細則（以下簡稱「條款」）。註冊成為《食港產片睇港產片》獎賞計劃的會員，即表示你同意受此等條款約束。
            </p>
            <p>
              <strong>1. 加入《食港產片睇港產片》獎賞計劃</strong>
              <br></br>
              如果你擁有香港的手機號碼並年滿18歲，你便可以申請成為《食港產片睇港產片》獎賞計劃會員。若你未滿18歲，則必須獲得你的家長或法定監護人的授權，並且必須確保他們同意你按照本條款及細則成為《食港產片睇港產片》獎賞計劃會員，你才可申請。
              你須確認向我們提供的所有資料均為完整、準確及最新的資料。如你的個人資料有任何變動，請電郵至
              <a href="mailto: info@calbee-promo.com">info@calbee-promo.com</a>
              更新個人資料，以便接收我們發出的任何訊息。
              為了保障其他會員的利益，我們有權就任何原因決定接受或拒絕你的《食港產片睇港產片》獎賞計劃會籍申請，或以其他方式終止你的賬戶（定義如下），並將不會對你承擔任何責任。
            </p>
            <p>
              <strong>2. 你的《食港產片睇港產片》獎賞計劃賬戶</strong>
              <br></br>
              在我們完成你的會員註冊並授予你會籍後，你便正式成為會員。
              你的賬戶將於活動期間內有效。
              你的賬戶須由你個人擁有，只有你本人才能累積電影時數、兌換獎賞。你不能將你的賬戶或電影時數出售、交換、轉移或轉讓予任何人。
              你不可以用同一包薯片的二維碼為多個賬戶賺取電影時數或兌換獎賞。
            </p>
            <p>
              <strong>3. 賺取電影時數</strong>
              <br></br>
              激活賬戶後，你可以購買卡樂B指定三款熱浪薯片，為熱浪薯片55克、超熱浪薯片68克及熱浪薯片105克，分別賺取15分鐘、20分鐘及25分鐘電影時數。
              當你購買指定熱浪薯片，掃描包裝內的二維碼，即可以賺取指定電影時數，累積至70分鐘電影時數就可以換領1張《梅艷芳》90分鐘電影贈券（百老匯院線），而累積至120分鐘電影時數則可以換領到1張120分鐘內的百老匯電影換票証。
              電影戲票將會於每星期平均分配，每星期只發放最多600張戲票。
              我們有權隨時修改電影戲票的數量，我們將於網站及/或應用程式上發佈有關任何重大變更的通知。
              你所賺取的積分將於掃描二維碼後3天內記入你的賬戶。在某些情況下，你的電影時數結餘未必會立即更新，但我們會盡快更新積分餘額。
              電影時數不能兌換現金或被購買。
              電影時數須要在限期前兌換。電影戲票的有效期為至一年，而《梅艷芳》電影除外，需根據百老匯電影院放映時間。
            </p>
            <p>
              <strong>4. 兌換獎賞</strong>
              <br></br>
              你可以使用你的賬戶內的電影時數來兌換我們提供獎賞。兌換每項獎賞所需的積分數量將由我們不時指定。你可以透過指定網站查看每項獎賞所需的積分。
              你必須在《食港產片睇港產片》網站，按照換領獎品的指示，方可兌換獎賞。
              我們將決定你兌換、使用、領取或寄送獎賞的方式。每項獎賞均有具體條款及細則，當中詳載各種享受所選擇獎賞的方式。
              任何獎賞的兌換，包括任何獎賞的使用或領取，將會受附加條款及細則的約束，而此等條款及細則將在我們的網站上發佈，或在你兌換獎賞前通知你。
              你在賬戶上可兌換的獎賞應由我們全權酌情規定、修訂、提供、給予或終止，且我們不必向你承擔任何責任。
              電影時數一經兌換成電影贈券或電影換票証，你將不能退換獎賞。
              獎賞不能兌換成現金。
            </p>
            <p>
              <strong>5. 確保你賬戶的安全</strong>
              <br></br>
              無論於任何時候，你須妥善保管你的賬戶登入密碼。如你的賬戶被其他人擅自使用或忘記密碼，請立即透過傳送電郵到
              <a href="mailto: info@calbee-promo.com">info@calbee-promo.com</a>
              通知我們。
            </p>
            <p>
              <strong>6. 《食港產片睇港產片》的變更</strong>
              <br></br>
              我們有權隨時更改網站內任何內容，包括（但不限於）任何獎賞、任何積分的累積或兌換、網上註冊程序
              或
              合作的商戶。如有任何重大更改，我們將透過電子郵件通知你，或在網站上公佈。在我們發出通知後，如你繼續使用你的賬戶，即表示你同意我們的有關修訂。若你不同意這些修訂，你可以隨時選擇取消你的賬戶。
            </p>
            <p>
              <strong>7. 條款的變更</strong>
              <br></br>
              我們可能會不時更新此等條款。如此等條款有任何重大更改，我們將透過於電子郵件或網站公佈的方式通知你。我們發出通知後，如你繼續使用你的賬戶，即表示你同意我們修訂後的條款。如你不同意這些修訂，你可以隨時選擇取消你的賬戶。
            </p>
            <p>
              <strong>8. 帳戶終止或暫停</strong>
              <br></br>
              你有權隨時以任何理由透過我們的網站取消你的賬戶，或者透過發電郵到
              <a href="mailto: info@calbee-promo.com">info@calbee-promo.com</a>
              取消你的賬戶。取消賬戶後，所有累積積分及尚未領取的獎賞將會作廢。
              在合理情況下，如我們認為你違反了任何此等條款，我們可能會暫停或終止你的帳戶（無論有否通知），以及/或向你追回我們或我們的合作商戶因而承受的任何損害及損失。
            </p>
            <p>
              <strong>9. 免責聲明</strong>
              <br></br>
              網站或應用程式上提供的任何類型的資料、內容及材料僅供你作一般參考。你參與及使用我們的網站、你的帳戶、你的積分、獎賞的風險須自行承擔。
              在法律允許的最大範圍內，我們的網站、你的帳戶、你的登入ID、你的積分及/或獎賞，以及所有提供或執行的相關資料、內容、材料及/或服務均「按原樣」提供，所有明示或暗示的條件或保證均不包括在內。
            </p>
            <p>
              <strong>10. 我們的責任</strong>
              <br></br>
              對於你遭受任何間接、特殊、附帶、懲罰性或後果性的損失或損害，包括疏忽，我們概不負責。
              在適用法律允許的最大範圍內，對於與此等條款、我們的網站、你的帳戶、你的積分、獎賞、我們的合作商戶所提供的任何產品及服務有關的任何損失或損害（包括一般的、特殊的、間接、直接、名義、懲罰性、附帶或後果性的損失或損害），我們不會在合同、侵權行為（包括疏忽）、違反法定義務或其他方面向你承擔責任，也不對與其相關並提供及執行的任何資料、內容、材料及/或服務承擔任何責任，不論我們是否事先被告知此類損失或損壞的可能性。
              如任何具有司法管轄權的法院發現本第12條上述規定的任何部分無效或不可執行，則我們對此等條款、我們的網站、應用程式、你的帳戶、你的積分及/或獎賞，或與此等條款相關並提供或執行的任何資料、內容、材料及/或服務的全部責任，將僅限於提供積分及/或替換作為爭議標的之任何獎賞，我們對此有絕對酌情權。
            </p>
            <p>
              <strong>聯繫方式</strong>
              <br></br>
              如你對此等條款有任何疑問，請發電郵至
              <a href="mailto: info@calbee-promo.com">info@calbee-promo.com</a>
              查詢。
            </p>
          </Paper>
        </Box>
        <Box className={classes.button}>
          <button onClick={() => history.goBack()}>
            <img src={BtnBack} className={classes.buttonImage} />
          </button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default TermsAndConditionsPage;
