import { React, Fragment, useEffect, useState } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { apiService } from "../../services";
import { API_URL } from "../../constants";
import moment from "moment";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_MyAcc@2x.png";
import MinsBlack from "../../image/Mins_Black.png";
import MinsGreen from "../../image/Mins_Green.png";
import BtnRankSmallGreen from "../../image/Btn_Rank_Small_Green.png";
import BtnRedeem from "../../image/Btn_Redeem.png";
import BtnQr from "../../image/Btn_QR.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingBottom: "5.5%",
    borderRadius: "15px !important",
  },
  heading: {
    fontWeight: "blod",
    marginBottom: "1%",
    marginTop: 0,
  },
  des: {
    fontWeight: "normal",
    marginBottom: 0,
  },
  minsGrid: {
    position: "relative",
    alignSelf: "flex-end",
  },
  minsBorder: {
    maxWidth: "100%",
    width: "75%",
  },
  buttonImage: {
    maxWidth: "100%",
  },
  fieldArea: {
    padding: "5% 5% 0 5%",
  },
  number: {
    position: "absolute",
    fontFamily: "DIN",
    right: "39%",
    marginTop: "0.7%",
  },
  numberSm: {
    fontSize: "8vw",
  },
  numberLg: {
    fontSize: "38px",
  },
  unit: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  unitSm: {
    fontSize: "3.4vw",
  },
  greenText: {
    color: "#00ab67",
  },
  buttonImageSmall: {
    maxWidth: "100%",
    width: "35%",
  },
  record: {
    backgroundColor: "#e6e7e9 !important",
    height: "22vh",
    borderRadius: "6px !important",
    boxShadow: "none !important",
    overflow: "auto",
  },
  table: {
    width: "100%",
    textAlign: "left",
    padding: "1% 1% 1% 2%",
    letterSpacing: 1,
  },
  tableTextSm: {
    fontSize: "3.3vw",
  },
  greenScore: {
    color: "#00ab67",
  },
  redScore: {
    color: "#ec1c24",
  },
  haedingSm: {
    fontSize: "3.5vw",
  },
  dateSm: {
    fontSize: "2.9vw !important",
  },
  period: {
    fontSize: 14,
    margin: 0,
  },
  rankingBtn: {
    marginTop: "25px !important",
    marginBottom: "25px !important",
  },
}));

const AccountPage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  const [profile, setProfile] = useState("");
  const [myPoint, setMyPoint] = useState("");
  const [myTranscation, setMyTranscation] = useState("");
  const [period, setPeriod] = useState("");

  useEffect(() => {
    apiService.get(API_URL.PROFILE).then((response) => {
      let data = response.data;
      setProfile(data);
    });
    apiService.get(API_URL.MY_POINT).then((response) => {
      let data = response.data;
      setMyPoint(data);
    });
    apiService.get(API_URL.MY_TRANSCATION).then((response) => {
      let data = response.data;
      setMyTranscation(data);
    });
    apiService.get(API_URL.PERIOD).then((response) => {
      let data = response.data;
      setPeriod(data);
    });
  }, []);

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box>
          <Paper elevation={4} className={classes.content}>
            <Grid container className={classes.fieldArea}>
              <Grid item xs={12}>
                <h4
                  className={clsx(classes.des, {
                    [classes.haedingSm]: matches,
                  })}
                >
                  你好，{profile.name}
                </h4>
              </Grid>
              <Grid item xs={6} className={classes.minsGrid}>
                <h4
                  className={clsx(classes.heading, {
                    [classes.haedingSm]: matches,
                  })}
                >
                  電影時數結餘
                </h4>
                <div
                  className={clsx(classes.number, {
                    [classes.numberSm]: matches,
                    [classes.numberLg]: !matches,
                  })}
                >
                  {myPoint.remaining}
                </div>
                <img className={classes.minsBorder} src={MinsBlack} />
                <div
                  className={clsx(classes.unit, {
                    [classes.unitSm]: matches,
                  })}
                >
                  電影時數
                </div>
              </Grid>
              <Grid item xs={6} className={classes.minsGrid}>
                <h4
                  className={clsx(classes.period, classes.greenText, {
                    [classes.dateSm]: matches,
                  })}
                >
                  {period && period.period && period.period.name}
                </h4>
                <h4
                  className={clsx(classes.period, classes.greenText, {
                    [classes.dateSm]: matches,
                  })}
                >
                  {period &&
                    period.period &&
                    period.period.start_date &&
                    period.period.end_date &&
                    "(" +
                      moment(period.period.start_date).format("M月D日") +
                      " - " +
                      moment(period.period.end_date).format("M月D日") +
                      ")"}
                </h4>
                <h4
                  className={clsx(classes.heading, classes.greenText, {
                    [classes.haedingSm]: matches,
                  })}
                >
                  本週累積電影時數
                </h4>
                <div
                  className={clsx(classes.number, classes.greenText, {
                    [classes.numberSm]: matches,
                    [classes.numberLg]: !matches,
                  })}
                >
                  {myPoint.ranking_score}
                </div>
                <img className={classes.minsBorder} src={MinsGreen} />
                <div
                  className={clsx(classes.unit, classes.greenText, {
                    [classes.unitSm]: matches,
                  })}
                >
                  電影時數
                </div>
              </Grid>
              <Grid item item xs={12} className={classes.rankingBtn}>
                <Link to="/ranking">
                  <button type="button" onClick>
                    <img
                      className={classes.buttonImageSmall}
                      src={BtnRankSmallGreen}
                    />
                  </button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <h4
                  className={clsx(classes.heading, {
                    [classes.haedingSm]: matches,
                  })}
                >
                  換領記錄
                </h4>
                <Paper className={classes.record}>
                  <table className={classes.table}>
                    <tr>
                      <th
                        width="23%"
                        className={clsx({
                          [classes.tableTextSm]: matches,
                        })}
                      >
                        日期
                      </th>
                      <th
                        className={clsx({
                          [classes.tableTextSm]: matches,
                        })}
                      >
                        時數/獎品
                      </th>
                      <th
                        width="23%"
                        className={clsx({
                          [classes.tableTextSm]: matches,
                        })}
                      >
                        詳情
                      </th>
                    </tr>

                    {myTranscation &&
                      myTranscation.map((item) => (
                        <tr
                          className={clsx({
                            [classes.tableTextSm]: matches,
                          })}
                        >
                          <td>
                            {moment(item.redemption_date).format("M月D日")}
                          </td>
                          <td>
                            {item.type == "code"
                              ? "電影時數"
                              : item.name + item.email_description + " x1"}
                          </td>
                          <td
                            className={clsx({
                              [classes.greenScore]: item.type == "code",
                              [classes.redScore]: item.type == "prize",
                            })}
                          >
                            {item.type == "code" && "+" + item.score + "分鐘"}
                            {item.type == "prize" &&
                              (item.score === 0
                                ? "已中獎"
                                : "-" + item.score + "分鐘")}
                          </td>
                        </tr>
                      ))}
                  </table>
                </Paper>
              </Grid>
              <Grid container>
                <Grid item item xs={6} style={{ marginTop: 20 }}>
                  <Link to="/redemption">
                    <button type="button" onClick>
                      <img className={classes.buttonImage} src={BtnRedeem} />
                    </button>
                  </Link>
                </Grid>
                <Grid item item xs={6} style={{ marginTop: 20 }}>
                  <Link to="/qr">
                    <button type="button" onClick>
                      <img className={classes.buttonImage} src={BtnQr} />
                    </button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AccountPage;
