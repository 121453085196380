import { Grid, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { React } from "react";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { authActions } from "../../actions/auth.action";
import { ROUTES } from "../../constants";
import AuthService from "../../services/auth.service";
import Footer2 from "../Footer2/index";

import Background from "../../image/BG_Sky_Blue.png";
import MenuButtonBack from "../../image/Btn_Menu_back.png";
import LogOutButton from "../../image/Log_Out.png";
import Logo from "../../image/Calbee_logo.png";
import RegButton from "../../image/Btn_Reg.png";
import LoginButton from "../../image/Btn_Login.png";
import Building from "../../image/Menu_Bulding@2x.png";

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: "480px",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    minHeight: "90vh",
    position: "relative",
  },
  header: {
    justifyContent: "space-between",
    paddingTop: "3.5%",
    paddingLeft: "3%",
    paddingRight: "3%",
    zIndex: 30,
  },
  content: {
    marginLeft: "5.5%",
    marginRight: "5.5%",
    paddingTop: "5.5%",
    borderRadius: "15px !important",
    bottom: 0,
    height: "80vh",
    position: "relative",
  },
  menuBtnBackLg: {
    height: 44,
  },
  menuBtnBackSm: {
    height: "10vw",
  },
  logOutBtn: {
    paddingTop: "20%",
  },
  logOutBtnLg: {
    height: 30,
  },
  logOutBtnSm: {
    height: "6vw",
  },
  menu: {
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    position: "relative",
    paddingTop: "10%",
    maxWidth: 360,
    zIndex: 200,
  },
  logoLg: {
    width: 150,
    marginBottom: 50,
  },
  logoSm: {
    width: "30vw",
    marginBottom: "10vw",
  },
  menuText: {
    margin: 0,
    position: "relative",
  },
  menuTextLg: {
    marginBottom: 25,
  },
  menuTextSm: {
    marginBottom: "5vw",
    fontSize: "4.5vw",
  },
  engText: {
    fontFamily: "DIN",
  },
  btn_imageLg: {
    maxWidth: "100%",
    marginTop: 30,
    position: "relative",
  },
  btn_imageSm: {
    width: "100%",
    marginTop: 30,
    position: "relative",
  },
  buttonGrid: {
    padding: "0 5% 0 5%",
  },
  button: {
    padding: "0 2% 0 2%",
  },
  bottomImg: {
    maxWidth: "100%",
    verticalAlign: "middle",
    position: "absolute",
    left: 0,
    bottom: "8.9%",
    zIndex: 0,
  },
  bottomImgSm: {
    bottom: "18vw",
  },
}));

const Menu = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  const { onClose } = props;

  const handleLogout = () => {
    authActions.logout();
    window.location.href = ROUTES.LOGIN;
  };

  return (
    <div>
      <Box className={classes.drawer}>
        <Box>
          <Grid container className={classes.header}>
            <Grid item>
              <button type="button" onClick={onClose}>
                <img
                  src={MenuButtonBack}
                  className={clsx({
                    [classes.menuBtnBackSm]: matches,
                    [classes.menuBtnBackLg]: !matches,
                  })}
                />
              </button>
            </Grid>
            {AuthService.isloggedIn() && (
              <Grid item>
                <button
                  onClick={() => {
                    handleLogout();
                    onClose();
                  }}
                >
                  <img
                    src={LogOutButton}
                    className={clsx(classes.logOutBtn, {
                      [classes.logOutBtnSm]: matches,
                      [classes.logOutBtnLg]: !matches,
                    })}
                  />
                </button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <img
                src={Logo}
                className={clsx({
                  [classes.logoSm]: matches,
                  [classes.logoLg]: !matches,
                })}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Paper elevation={4} className={classes.content}>
            <Grid container className={classes.menu}>
              <Grid item item xs={12}>
                <Link to="/">
                  <button type="button" onClick>
                    <h3
                      className={clsx(classes.menuText, {
                        [classes.menuTextSm]: matches,
                        [classes.menuTextLg]: !matches,
                      })}
                    >
                      主頁
                    </h3>
                  </button>
                </Link>
              </Grid>
              <Grid item item xs={12}>
                <Link to="/account">
                  <button type="button" onClick>
                    <h3
                      className={clsx(classes.menuText, {
                        [classes.menuTextSm]: matches,
                        [classes.menuTextLg]: !matches,
                      })}
                    >
                      我的帳戶
                    </h3>
                  </button>
                </Link>
              </Grid>
              <Grid item item xs={12}>
                <Link to="/game-intro">
                  <button type="button" onClick>
                    <h3
                      className={clsx(classes.menuText, {
                        [classes.menuTextSm]: matches,
                        [classes.menuTextLg]: !matches,
                      })}
                    >
                      遊戲玩法
                    </h3>
                  </button>
                </Link>
              </Grid>
              <Grid item item xs={12}>
                <Link to="/qr">
                  <button type="button" onClick>
                    <h3
                      className={clsx(classes.menuText, {
                        [classes.menuTextSm]: matches,
                        [classes.menuTextLg]: !matches,
                      })}
                    >
                      掃描<span className={classes.engText}>QR Code</span>
                    </h3>
                  </button>
                </Link>
              </Grid>
              <Grid item item xs={12}>
                <Link to="/redemption">
                  <button type="button" onClick>
                    <h3
                      className={clsx(classes.menuText, {
                        [classes.menuTextSm]: matches,
                        [classes.menuTextLg]: !matches,
                      })}
                    >
                      換領電影戲票
                    </h3>
                  </button>
                </Link>
              </Grid>
              <Grid item item xs={12}>
                <Link to="/ranking">
                  <button type="button" onClick>
                    <h3
                      className={clsx(classes.menuText, {
                        [classes.menuTextSm]: matches,
                        [classes.menuTextLg]: !matches,
                      })}
                    >
                      排行榜
                    </h3>
                  </button>
                </Link>
              </Grid>
              {!AuthService.isloggedIn() && (
                <Grid container className={classes.buttonGrid}>
                  <Grid item item xs={6} className={classes.button}>
                    <Link to="/registration" onClick={onClose}>
                      <img
                        className={clsx({
                          [classes.btn_imageSm]: matches,
                          [classes.btn_imageLg]: !matches,
                        })}
                        src={RegButton}
                      />
                    </Link>
                  </Grid>
                  <Grid item item xs={6} className={classes.button}>
                    <Link to="/login" onClick={onClose}>
                      <img
                        className={clsx({
                          [classes.btn_imageSm]: matches,
                          [classes.btn_imageLg]: !matches,
                        })}
                        src={LoginButton}
                      />
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
        <img
          src={Building}
          className={clsx(classes.bottomImg, {
            [classes.bottomImgSm]: matches,
          })}
        />
        <Footer2 onClose={onClose} />
      </Box>
    </div>
  );
};

export default Menu;
