import { React, Fragment, useState, useEffect } from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory, useParams } from "react-router-dom";
import QrReader from "react-qr-reader";
import { SuccessDialog } from "./component/SuccessDialog";
import { FailDialog } from "./component/FailDialog";
import { apiService } from "../../services";
import { API_URL } from "../../constants";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_ScanQR@2x.png";
import BtnBack from "../../image/Btn_Back.png";
import BtnMyAcc from "../../image/Btn_Conti_Redeem.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "5%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  content: {
    backgroundColor: "black !important",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    borderRadius: "15px !important",
  },
  qrBox: {
    margin: "5.5%",
  },
  qr: {
    maxWidth: "100%",
    padding: "2%",
    borderRadius: "5px",
  },
  btnImage: {
    maxWidth: "100%",
    width: "100%",
    padding: "0 2% 0 2%",
  },
  buttonGrid: {
    maxWidth: "100%",
    padding: "10% 6% 0 6%",
  },
  eng: {
    fontFamily: "DIN",
  },
  error: {
    color: "#ec1c24",
    fontSize: 16,
  },
  des: {
    padding: "0 5% 0 5%",
  },
}));

const QrCodePage = () => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [entity, setEntity] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  let { id } = useParams();
  let history = useHistory();

  const handleScan = (data) => {
    if (data) {
      var beforeQr = data.lastIndexOf("/");
      var qrCodeApi = data.substring(beforeQr + 1);

      apiService
        .post(API_URL.QR_CODE + "/" + qrCodeApi)
        .then((response) => {
          setEntity(response.data);
          setOpenSuccess(true);
        })
        .catch(function (error) {
          setOpenFail(true);
        });
    }
  };

  const handleError = (err) => {
    if (err) {
      setErrorMsg("請重新整理頁面並允許「calbee-promo.com」使用您的攝影機");
    }
  };

  useEffect(() => {
    setErrorMsg("");
    if (id) {
      apiService
        .post(API_URL.QR_CODE + "/" + id)
        .then((response) => {
          setEntity(response.data);
          setOpenSuccess(true);
        })
        .catch(function (error) {
          setOpenFail(true);
        });
    }
  }, []);

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box className={classes.des}>
          <h4>
            請掃描包裝內的<span className={classes.eng}>QR Code</span>
          </h4>
          <h4 className={classes.error}>{errMsg}</h4>
        </Box>
        <Box>
          <Paper className={classes.content}>
            {!openSuccess && !openFail && (
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                className={classes.qr}
              />
            )}
          </Paper>
        </Box>
        <Box>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={6}>
              <Link to="/" type="button">
                <button>
                  <img src={BtnBack} className={classes.btnImage}></img>
                </button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link to="/account">
                <button type="button">
                  <img src={BtnMyAcc} className={classes.btnImage}></img>
                </button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SuccessDialog
        onClose={() => setOpenSuccess(false)}
        open={openSuccess}
        entity={entity}
      />
      <FailDialog onClose={() => setOpenFail(false)} open={openFail} />
    </Fragment>
  );
};

export default QrCodePage;
