import { React, Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { apiService } from "../../services";
import { API_URL } from "../../constants";
import moment from "moment";

import background from "../../image/BG_Sky_Blue.png";
import backgroundColor from "../../image/BG_Blue@2x.png";
import Title from "../../image/Ranking_Panel@2x.png";
import RankingBarRed from "../../image/Ranking_Bar_Top1.png";
import RankingBarGreen from "../../image/Ranking_Bar_Green.png";
import RankingBarYellow from "../../image/Ranking_Bar_Yellow.png";
import Dot from "../../image/6dots.png";
import Divider from "../../image/Divider_Line@2x.png";
import BtnBack from "../../image/Btn_Back.png";
import BtnJoin from "../../image/Btn_Join.png";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "center",
    position: "relative",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  background: {
    backgroundImage: `url(${backgroundColor})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    maxWidth: "100%",
    paddingBottom: "10%",
    minHeight: "60vh",
  },
  header: {
    paddingTop: 20,
  },
  title: {
    paddingTop: 38,
    maxWidth: "100%",
    width: "92%",
  },
  rankArea: {
    padding: "0 5% 0 5%",
  },
  rankingBarGrid: {
    position: "relative",
  },
  rankingContentTop: {
    marginTop: "13.7%",
    position: "absolute",
  },
  rankingContent: {
    marginTop: "7%",
    position: "absolute",
  },
  rankingBar: {
    maxWidth: "100%",
  },
  rankTextGrid: {
    paddingLeft: "8%",
    textAlignLast: "left",
  },
  rankText: {
    letterSpacing: 1,
    margin: 0,
  },
  DIN: {
    fontFamily: "DIN",
  },
  userNameGrid: {
    alignSelf: "center",
    textAlignLast: "left",
  },
  userName: {
    letterSpacing: 0,
  },
  buttonImage: {
    maxWidth: "100%",
  },
  number: {
    position: "absolute",
    fontFamily: "DIN",
    right: "16.5%",
  },
  titleSm: {
    fontSize: "4.5vw",
  },
  textSm: {
    fontSize: "3.5vw",
  },
  desSm: {
    fontSize: "3.5vw",
  },
  textLg: {
    fontSize: "18px",
  },
  rankNoSm: {
    fontSize: "5vw",
  },
  rankNoLg: {
    fontSize: "22px",
  },
  noSm: {
    fontSize: "5vw",
  },
  noLg: {
    fontSize: "26px",
  },
  dotGrid: {
    padding: "3% 0 4% 0",
  },
  dotdotdot: {
    maxwWidth: "100%",
    width: "1.1%",
  },
  buttonGrid: {
    padding: "7% 4% 0 4%",
  },
  dividerGrid: {
    padding: "3% 0 4% 0",
  },
  divider: {
    maxwWidth: "100%",
    width: "97%",
  },
}));

const RankingPage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:480px)");
  const [ranking, setRanking] = useState("");
  const [myPoint, setMyPoint] = useState("");
  const [profile, setProfile] = useState("");
  let history = useHistory();

  useEffect(() => {
    apiService.get(API_URL.RANKING).then((response) => {
      let data = response.data;
      setRanking(data);
    });
    apiService.get(API_URL.MY_POINT).then((response) => {
      let data = response.data;
      setMyPoint(data);
    });
    apiService.get(API_URL.PROFILE).then((response) => {
      let data = response.data;
      setProfile(data);
    });
  }, []);

  return (
    <Fragment>
      <Box>
        <Box className={classes.box}>
          <img src={Title} className={classes.title}></img>
        </Box>
        <Box className={classes.background}>
          <Box className={classes.header}>
            <h3
              className={clsx(classes.rankText, {
                [classes.titleSm]: matches,
              })}
            >
              我的排名
            </h3>
            <h4
              className={clsx({
                [classes.desSm]: matches,
              })}
            >
              {ranking && ranking.period && ranking.period.name}
              {ranking &&
                ranking.period &&
                ranking.period.start_date &&
                ranking.period.end_date &&
                "(" +
                  moment(ranking.period.start_date).format("M月D日") +
                  " - " +
                  moment(ranking.period.end_date).format("M月D日") +
                  ")"}
            </h4>
          </Box>
          <Box>
            <Grid container className={classes.rankArea}>
              <Grid item xs={12} className={classes.rankingBarGrid}>
                <Grid container className={classes.rankingContent}>
                  <Grid item xs={3.5} className={classes.rankTextGrid}>
                    <h4
                      className={clsx(classes.rankText, {
                        [classes.textSm]: matches,
                        [classes.textLg]: !matches,
                      })}
                    >
                      第
                      <span
                        className={clsx(classes.DIN, {
                          [classes.rankNoSm]: matches,
                          [classes.rankNoLg]: !matches,
                        })}
                      >
                        {myPoint.ranking === 0 ? "-" : myPoint.ranking}
                      </span>
                      名
                    </h4>
                  </Grid>
                  <Grid item xs={4.3} className={classes.userNameGrid}>
                    <h4
                      className={clsx(
                        classes.DIN,
                        classes.rankText,
                        classes.userName,
                        {
                          [classes.textSm]: matches,
                          [classes.textLg]: !matches,
                        }
                      )}
                    >
                      {profile.name}
                    </h4>
                  </Grid>
                  <Grid item xs={4.2} style={{ margin: -2 }}>
                    <div
                      className={clsx(classes.number, {
                        [classes.noSm]: matches,
                        [classes.noLg]: !matches,
                      })}
                    >
                      {myPoint.ranking_score}
                    </div>
                  </Grid>
                </Grid>
                <img src={RankingBarGreen} className={classes.rankingBar}></img>
              </Grid>
              <Grid item xs={12} className={classes.dividerGrid}>
                <img src={Divider} className={classes.divider}></img>
              </Grid>
              <Grid item xs={12} className={classes.rankingBarGrid}>
                <Grid container className={classes.rankingContentTop}>
                  <Grid item xs={3.5} className={classes.rankTextGrid}>
                    <h4
                      className={clsx(classes.rankText, {
                        [classes.textSm]: matches,
                        [classes.textLg]: !matches,
                      })}
                    >
                      第
                      <span
                        className={clsx(classes.DIN, {
                          [classes.rankNoSm]: matches,
                          [classes.rankNoLg]: !matches,
                        })}
                      >
                        1
                      </span>
                      名
                    </h4>
                  </Grid>
                  <Grid item xs={4.3} className={classes.userNameGrid}>
                    <h4
                      className={clsx(
                        classes.DIN,
                        classes.rankText,
                        classes.userName,
                        {
                          [classes.textSm]: matches,
                          [classes.textLg]: !matches,
                        }
                      )}
                    >
                      {ranking.score &&
                        (ranking.score[0]?.name == null
                          ? "--"
                          : ranking.score[0]?.name)}
                    </h4>
                  </Grid>
                  <Grid item xs={4.2} style={{ margin: -2 }}>
                    <div
                      className={clsx(classes.number, {
                        [classes.noSm]: matches,
                        [classes.noLg]: !matches,
                      })}
                    >
                      {ranking.score &&
                        (ranking.score[0]?.chip_qr_codes_sum_score == null
                          ? 0
                          : ranking.score[0]?.chip_qr_codes_sum_score)}
                    </div>
                  </Grid>
                </Grid>
                <img src={RankingBarRed} className={classes.rankingBar}></img>
              </Grid>
              <Grid item xs={12} className={classes.rankingBarGrid}>
                <Grid container className={classes.rankingContent}>
                  <Grid item xs={3.5} className={classes.rankTextGrid}>
                    <h4
                      className={clsx(classes.rankText, {
                        [classes.textSm]: matches,
                        [classes.textLg]: !matches,
                      })}
                    >
                      第
                      <span
                        className={clsx(classes.DIN, {
                          [classes.rankNoSm]: matches,
                          [classes.rankNoLg]: !matches,
                        })}
                      >
                        2
                      </span>
                      名
                    </h4>
                  </Grid>
                  <Grid item xs={4.3} className={classes.userNameGrid}>
                    <h4
                      className={clsx(
                        classes.DIN,
                        classes.rankText,
                        classes.userName,
                        {
                          [classes.textSm]: matches,
                          [classes.textLg]: !matches,
                        }
                      )}
                    >
                      {ranking.score &&
                        (ranking.score[1]?.name == null
                          ? "--"
                          : ranking.score[1]?.name)}
                    </h4>
                  </Grid>
                  <Grid item xs={4.2} style={{ margin: -2 }}>
                    <div
                      className={clsx(classes.number, {
                        [classes.noSm]: matches,
                        [classes.noLg]: !matches,
                      })}
                    >
                      {ranking.score &&
                        (ranking.score[1]?.chip_qr_codes_sum_score == null
                          ? 0
                          : ranking.score[1]?.chip_qr_codes_sum_score)}
                    </div>
                  </Grid>
                </Grid>
                <img src={RankingBarGreen} className={classes.rankingBar}></img>
              </Grid>
              <Grid item xs={12} className={classes.rankingBarGrid}>
                <Grid container className={classes.rankingContent}>
                  <Grid item xs={3.5} className={classes.rankTextGrid}>
                    <h4
                      className={clsx(classes.rankText, {
                        [classes.textSm]: matches,
                        [classes.textLg]: !matches,
                      })}
                    >
                      第
                      <span
                        className={clsx(classes.DIN, {
                          [classes.rankNoSm]: matches,
                          [classes.rankNoLg]: !matches,
                        })}
                      >
                        3
                      </span>
                      名
                    </h4>
                  </Grid>
                  <Grid item xs={4.3} className={classes.userNameGrid}>
                    <h4
                      className={clsx(
                        classes.DIN,
                        classes.rankText,
                        classes.userName,
                        {
                          [classes.textSm]: matches,
                          [classes.textLg]: !matches,
                        }
                      )}
                    >
                      {ranking.score &&
                        (ranking.score[2]?.name == null
                          ? "--"
                          : ranking.score[2]?.name)}
                    </h4>
                  </Grid>
                  <Grid item xs={4.2} style={{ margin: -2 }}>
                    <div
                      className={clsx(classes.number, {
                        [classes.noSm]: matches,
                        [classes.noLg]: !matches,
                      })}
                    >
                      {ranking.score &&
                        (ranking.score[2]?.chip_qr_codes_sum_score == null
                          ? 0
                          : ranking.score[2]?.chip_qr_codes_sum_score)}
                    </div>
                  </Grid>
                </Grid>
                <img
                  src={RankingBarYellow}
                  className={classes.rankingBar}
                ></img>
              </Grid>
              {/* <Grid item xs={12} className={classes.rankingBarGrid}>
                <Grid container className={classes.rankingContent}>
                  <Grid item xs={3.5} className={classes.rankTextGrid}>
                    <h4
                      className={clsx(classes.rankText, {
                        [classes.textSm]: matches,
                        [classes.textLg]: !matches,
                      })}
                    >
                      第
                      <span
                        className={clsx(classes.DIN, {
                          [classes.rankNoSm]: matches,
                          [classes.rankNoLg]: !matches,
                        })}
                      >
                        4
                      </span>
                      名
                    </h4>
                  </Grid>
                  <Grid item xs={4.3} className={classes.userNameGrid}>
                    <h4
                      className={clsx(
                        classes.DIN,
                        classes.rankText,
                        classes.userName,
                        {
                          [classes.textSm]: matches,
                          [classes.textLg]: !matches,
                        }
                      )}
                    >
                      {ranking.score &&
                        (ranking.score[3]?.name == null
                          ? "--"
                          : ranking.score[3]?.name)}
                    </h4>
                  </Grid>
                  <Grid item xs={4.2} style={{ margin: -2 }}>
                    <div
                      className={clsx(classes.number, {
                        [classes.noSm]: matches,
                        [classes.noLg]: !matches,
                      })}
                    >
                      {ranking.score &&
                        (ranking.score[3]?.chip_qr_codes_sum_score == null
                          ? 0
                          : ranking.score[3]?.chip_qr_codes_sum_score)}
                    </div>
                  </Grid>
                </Grid>
                <img src={RankingBarGreen} className={classes.rankingBar}></img>
              </Grid>
              <Grid item xs={12} className={classes.dotGrid}>
                <img src={Dot} className={classes.dotdotdot}></img>
              </Grid>
              <Grid item xs={12} className={classes.rankingBarGrid}>
                <Grid container className={classes.rankingContent}>
                  <Grid item xs={3.5} className={classes.rankTextGrid}>
                    <h4
                      className={clsx(classes.rankText, {
                        [classes.textSm]: matches,
                        [classes.textLg]: !matches,
                      })}
                    >
                      第
                      <span
                        className={clsx(classes.DIN, {
                          [classes.rankNoSm]: matches,
                          [classes.rankNoLg]: !matches,
                        })}
                      >
                        151
                      </span>
                      名
                    </h4>
                  </Grid>
                  <Grid item xs={4.3} className={classes.userNameGrid}>
                    <h4
                      className={clsx(
                        classes.DIN,
                        classes.rankText,
                        classes.userName,
                        {
                          [classes.textSm]: matches,
                          [classes.textLg]: !matches,
                        }
                      )}
                    >
                      {ranking.score &&
                        (ranking.score[4]?.name == null
                          ? "--"
                          : ranking.score[4]?.name)}
                    </h4>
                  </Grid>
                  <Grid item xs={4.2} style={{ margin: -2 }}>
                    <div
                      className={clsx(classes.number, {
                        [classes.noSm]: matches,
                        [classes.noLg]: !matches,
                      })}
                    >
                      {ranking.score &&
                        (ranking.score[4]?.chip_qr_codes_sum_score == null
                          ? 0
                          : ranking.score[4]?.chip_qr_codes_sum_score)}
                    </div>
                  </Grid>
                </Grid>
                <img
                  src={RankingBarYellow}
                  className={classes.rankingBar}
                ></img>
              </Grid> */}
              <Grid container className={classes.buttonGrid}>
                <Grid item item xs={6}>
                  <button type="button" onClick={() => history.goBack()}>
                    <img className={classes.buttonImage} src={BtnBack} />
                  </button>
                </Grid>
                <Grid item item xs={6}>
                  <Link to="/account">
                    <button type="button" onClick>
                      <img className={classes.buttonImage} src={BtnJoin} />
                    </button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default RankingPage;
