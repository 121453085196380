import { React, Fragment } from "react";
import { Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

import background from "../../image/BG_Sky_Blue.png";
import Title from "../../image/Title_Policy@2x.png";
import BtnBack from "../../image/Btn_Back.png";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    paddingBottom: "5%",
    maxWidth: "100%",
    minHeight: "90vh",
  },
  header: {
    paddingTop: 20,
    marginBottom: -45,
    position: "absolute",
  },
  title: {
    maxWidth: "100%",
    width: "60%",
  },
  body: {
    paddingTop: "36%",
  },
  content: {
    paddingTop: "8%",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    padding: "4%",
    borderRadius: "15px !important",
    textAlign: "left",
    maxWidth: "100%",
    height: "55vh",
    overflow: "auto",
  },
  button: {
    paddingTop: "7%",
  },
  buttonImage: {
    maxWidth: "100%",
    width: "40%",
  },
}));

const PrivacyPolicyPage = () => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <Fragment>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <img src={Title} className={classes.title} />
        </Box>
        <Box className={classes.body}>
          <Paper elevation={4} className={classes.content}>
            <p>
              <strong>1. 私隱政策</strong>
              <br></br>
              我們的私隱政策訂明我們使用你的個人資料的目的、我們與誰分享你的個人資料，以及你在我們如何收集及使用你的個人資料上所擁有的選擇。
            </p>
            <p>
              <strong>2. 連結</strong>
              <br></br>
              我們的網站及發送予你的任何電子通訊或以網上或數碼方式提供予你的任何材料可能包含引導至由第三方（包括我們的合作夥伴，或我們的關聯公司）寄存、給予或提供的其他網站、內容或資源的連結。你理解並同意我們：
              <br></br>(a) 對這些第三方網站、內容或資源沒有任何控制及監控權；
              <br></br>
              (b)不提供任何承諾、陳述或保證，亦不對任何該等第三方網站、內容或資源（或在該等第三方網站或資源上促銷、介紹或提供的任何產品、貨品或服務）負責；以及
              <br></br>
              (c)不支持任何該等第三方網站、內容或資源，或該等第三方網站或資源提供的任何廣告、產品、商品、服務或其他材料。
            </p>
            <p>
              <strong>3. 爭議的解決</strong>
              <br></br>
              此等條款及由此引起或與其相關的任何爭議或事宜，或與積分、獎賞、你的帳戶、我們的網站有關任何爭議或事宜，均受香港特別行政區法例管轄並按其詮釋。閣下同意接受香港特別行政區法院的專屬司法管轄權管轄。
            </p>
            <p>
              <strong>4 . 一般條款</strong>
              <br></br>
              如任何此等條款被任何具有司法管轄權的法院或法庭裁定為無效、非法或不可執行，此等條款將會被分割，而其餘下條款將繼續完全有效，猶如此等條款是在沒有無效、非法或不可執行的條款的情況下訂立。
              此等條款和我們的私隱政策，以及本文提及的及/或在我們的網站或應用程式上公佈的任何其他條款及細則，包含你與我們之間的完整協議。
              我們未能或延遲根據此等條款行使任何權利或對違約採取行動，並不意味著豁免該項權利或違約。我們對此等條款的任何權利或違約的任何豁免必須以書面形式作出，並且該豁免僅限於其中所述的特定權利或違約。
              此等條款是你的個人權利，你不得轉移你在此等條款下的任何權利或義務。我們可以無需你的同意將我們的權利或義務轉讓予任何第三方。
              在任何情況下，《合約（第三者權利）條例》（第623章）不適用於此等條款。
            </p>
            <p>
              <strong>
                我們非常重視你的私隱，並希望就收集你個人資料的事宜作詳細解釋個人資料收集聲明
              </strong>
              <br></br>
              如你向我們出示你的電郵地址，以加入我們的獎賞計劃，即表示你已同意此個人資料收集聲明。會員可賺取及兌換積分，以換取及贏取產品及電影換票證。你必須年滿18歲才可以申請加入會員；若你未滿18歲，則必須獲得你的家長或法定監護人的授權，並且必須確保他們同意你按照本個人資料收集聲明成為會員，你才可申請。
            </p>
            <p>
              <strong>你的個人資料的使用準則</strong>
              <br></br>
              我們只會將你的個人資料用於以下用途:
              <br></br>
              1.管理及維護你的帳戶並提供客戶支援，例如：更新你的帳戶、驗證你的身份、透過整合我們、已經持有的你的個人資料進行建立一份關於你的檔案、處理積分發放及獎賞兌換、完成任何交易、以及與你進行通訊；
              <br></br>
              2.調查或處理任何事件、索償或爭議、辯護或提出任何索償或行動、或遵守適用法律或任何監管機構、法院或政府機構的任何要求。
            </p>
            <p>
              <strong>與我們共享你個人資料的對象</strong>
              <br></br>
              為履行以上任何目的，我們可能會與我們的合作夥伴、專業顧問或卡樂B集團的成員的任何服務供應商共享你的個人資料。該等服務供應商將代表我們處理個人資料，以協助我們管理和營運網站、進行資料整合及匿名化個人資料、或執行上述的任何目的。不過，我們不會與任何第三方共享你的個人資料，以使他們向你發送任何有關他們自己的產品或服務的直接營銷材料。
            </p>
            <p>
              <strong>你的權利</strong>
              <br></br>
              你有權隨時發送電郵至
              <a href="mailto: info@calbee-promo.com">info@calbee-promo.com</a>
              以查閱及更正我們所持有的關於你的個人資料。
            </p>
            <p>
              <strong>聯繫方式</strong>
              <br></br>
              如你對此等條款有任何疑問，請發電郵至
              <a href="mailto: info@calbee-promo.com">info@calbee-promo.com</a>
              查詢。
            </p>
          </Paper>
        </Box>
        <Box className={classes.button}>
          <button onClick={() => history.goBack()}>
            <img src={BtnBack} className={classes.buttonImage} />
          </button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default PrivacyPolicyPage;
