export const API_HOSTNAME = "/api";

export const API_URL = {
  // Registration Page
  REGISTER: API_HOSTNAME + "/auth/register",

  // Login Page
  LOGIN: API_HOSTNAME + "/auth/login",

  // Verify Page
  ACCOUNT_VERIFICATION: API_HOSTNAME + "/auth/email/verify",

  // Account Page
  PROFILE: API_HOSTNAME + "/auth/profile",
  MY_POINT: API_HOSTNAME + "/myPoint",
  MY_TRANSCATION: API_HOSTNAME + "/myTranscation",
  PERIOD: API_HOSTNAME + "/period",

  // Redemption Page
  AVAILABLE_PRIZE: API_HOSTNAME + "/availablePrize",
  PRIZE_REDEEM: API_HOSTNAME + "/prizes/redeem",

  // Forgot Password Page
  FORGOT: API_HOSTNAME + "/auth/password/forget",

  // Reset Password Page
  RESET_PASSWORD: API_HOSTNAME + "/auth/password/reset",

  // QR Code Page
  QR_CODE: API_HOSTNAME + "/qr",

  // Ranking Page
  RANKING: API_HOSTNAME + "/ranking",
};
