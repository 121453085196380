import { React } from "react";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import { Box, Grid } from "@material-ui/core";

import BtnContfirm from "../../../image/Btn_Confirm_Green.png";
import BtnClose from "../../../image/Btn_Close.png";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: "480px",
    margin: "auto",
  },
  box: {
    textAlign: "center",
    maxWidth: "100%",
    position: "relative",
  },
  closeGrid: {
    position: "absolute",
    padding: "5%",
    maxWidth: "100%",
    zIndex: 10,
    textAlignLast: "right",
  },
  close: {
    width: "10%",
  },
  button: {
    maxWidth: "100%",
    width: "100%",
    justifyContent: "right !important",
    textAlign: "right",
  },
  grid: {
    maxWidth: "100%",
    padding: "30% 4% 30% 4%",
  },
  heading: {
    fontWeight: "normal",
    margin: 0,
  },
  moviePoster: {
    marginTop: "10%",
    width: "40%",
  },
  des: {
    padding: "0 10% 2% 10%",
    fontWeight: "normal",
  },
  btnImage: {
    paddingTop: "10%",
    maxWidth: "100%",
    width: "45%",
  },
}));

export function SuccessDialog(props) {
  const classes = useStyles();
  const { open, onClose, entity } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      className={classes.dialog}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <Box className={classes.box}>
        <Grid container className={classes.closeGrid}>
          <Grid item xs={12}>
            <button onClick={onClose} className={classes.button}>
              <img className={classes.close} src={BtnClose} />
            </button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <h4 className={classes.heading}>你已成功獲得</h4>
            <h3 className={classes.heading}>{entity.score}分鐘</h3>
            <h4 className={classes.heading}>電影時數</h4>
          </Grid>
          <Grid item xs={12}>
            <button type="button" onClick={onClose}>
              <img className={classes.btnImage} src={BtnContfirm} />
            </button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
